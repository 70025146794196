<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/the_rent_roll_broker_resources.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">Industry News & Advice</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>Resources</li>
                  <li>Industry News & Advice</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
    </div>

    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div class="post-no-line">
              <div v-if="article && !isLoading" class="post-body">
                <div class="entry-header">
                  <h2 class="entry-title text-uppercase">
                    {{ article.title }}
                  </h2>
                  <div class="post-meta">
                    <span class="post-meta-date"
                      ><i class="fa fa-calendar"></i>
                      {{ article.active_date }}</span
                    >
                    <router-link
                      v-if="article.previous_id"
                      :to="{
                        name: 'industry_news_advice_show',
                        params: { id: article.previous_id }
                      }"
                    >
                      <span class="post-meta-date"
                        ><i class="fa fa-arrow-circle-o-left"></i>
                        PREVIOUS</span
                      >
                    </router-link>
                    <router-link
                      v-if="article.next_id"
                      :to="{
                        name: 'industry_news_advice_show',
                        params: { id: article.next_id }
                      }"
                    >
                      <span class="post-meta-date"
                        ><i class="fa fa-arrow-circle-o-right"></i> NEXT</span
                      >
                    </router-link>
                    <router-link :to="{ name: 'industry_news_advice' }">
                      <span class="post-meta-date"
                        ><i class="fa fa-arrow-circle-o-up"></i> BACK</span
                      >
                    </router-link>
                  </div>
                </div>
                <!-- header end -->

                <div class="gap-40"></div>

                <!-- Video or Image -->
                <div class="entry-content">
                  <div class="col-md-12">
                    <!-- Video -->
                    <div class="video-wrapper-grey" v-if="article.youtube_link">
                      <div class="full-width-video-container">
                        <iframe
                          :src="
                            article.youtube_link.replace(
                              'youtu.be',
                              'youtube.com/embed'
                            )
                          "
                          frameborder="0"
                          allowfullscreen="true"
                        ></iframe>
                      </div>

                      <p class="font-white">
                        {{ article.youtube_link_description }}
                      </p>
                    </div>

                    <div
                      v-if="
                        !article.youtube_link &&
                          article.full_article_page_image_url
                      "
                    >
                      <img
                        :src="article.full_article_page_image_url"
                        class="img-responsive"
                        :alt="article.title"
                      />
                    </div>
                    <!-- Video end -->
                  </div>
                  <!-- Col end -->

                  <div class="gap-40"></div>

                  <div class="col-md-12">
                    <div class="" v-html="article.article_body"></div>
                  </div>
                  <!-- Col end -->
                </div>
                <div class="col-md-12">
                  <br /><br />
                  <router-link
                    v-if="!article.previous_id"
                    :to="{ name: 'industry_news_advice' }"
                    class="btn btn-primary"
                  >
                    Back
                  </router-link>
                  <router-link
                    v-if="article.previous_id"
                    class="btn btn-primary"
                    :to="{
                      name: 'industry_news_advice_show',
                      params: { id: article.previous_id }
                    }"
                  >
                    Previous </router-link
                  >&nbsp;&nbsp;
                  <router-link
                    v-if="article.next_id"
                    class="btn btn-primary"
                    :to="{
                      name: 'industry_news_advice_show',
                      params: { id: article.next_id }
                    }"
                  >
                    Next </router-link
                  >&nbsp;&nbsp;
                  <router-link
                    v-if="article.previous_id"
                    :to="{ name: 'industry_news_advice' }"
                    class="btn btn-primary"
                  >
                    Back
                  </router-link>
                </div>
              </div>
              <div v-if="isLoading" class="post-body text-center">
                <div class="loading"></div>
              </div>
              <div v-if="!article && !isLoading" class="post-body text-center">
                <p>Article Not Found</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <Archive @changeYear="changeYear" :articles-years="articlesYears" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { news } from "@/lib/axios";
import Archive from "@/components/Archive";
import orderBy from "lodash-es/orderBy";

export default {
  components: {
    Archive
  },
  data() {
    return {
      id: this.$route.params.id,
      article: null,
      documents: [],
      isLoading: false,
      articlesYears: [],
      params: {
        page: 1,
        selected_year: this.$route.query.year ? this.$route.query.year : null,
        year_filter: true
      }
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.loadNewsPerId();
    this.loadNewsYears();
  },
  methods: {
    async loadNewsYears() {
      try {
        const { data } = await news.get(`/articles/get-years`);
        this.articlesYears = data.data;
        const year = orderBy(data.data, "desc")[0];
        this.params.selected_year = year ? year : null;
      } catch (e) {
        console.log(e, "e");
      }
    },
    async loadNewsPerId() {
      this.isLoading = true;
      try {
        const { data } = await news.get(`/articles/${this.id}`);
        this.article = data.data;
        this.isLoading = false;
      } catch (e) {
        console.log(e, "e");
        this.isLoading = false;
      }
    },
    changeYear(year) {
      this.$router.replace({
        name: "industry_news_advice",
        query: { year: year }
      });
    }
  },
  watch: {
    $route() {
      this.id = this.$route.params.id;
      this.loadNewsPerId();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style></style>
