import app from "./modules/app";
import auth from "./modules/auth";
import stats from "./modules/stats";
import resources from "./modules/resources";
import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["app", "auth"]
});
export default createStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    app: app,
    auth: auth,
    stats: stats,
    resources: resources
  },
  plugins: [vuexLocal.plugin]
});
