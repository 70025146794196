import axios from "axios";

export const listings = axios.create({
  baseURL: process.env.VUE_APP_LISTINGS_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const geoLocations = axios.create({
  baseURL: process.env.VUE_APP_GEO_LOCATION_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const identities = axios.create({
  baseURL: process.env.VUE_APP_IDENTITY_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const news = axios.create({
  baseURL: process.env.VUE_APP_NEWS_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const documents = axios.create({
  baseURL: process.env.VUE_APP_DOCUMENTS_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const affiliates = axios.create({
  baseURL: process.env.VUE_APP_AFFILIATES_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const configs = axios.create({
  baseURL: process.env.VUE_APP_CONFIG_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const businesses = axios.create({
  baseURL: process.env.VUE_APP_BUSINESS_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const customers = axios.create({
  baseURL: process.env.VUE_APP_CUSTOMER_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const templateCA = axios.create({
  baseURL: process.env.VUE_APP_INTEREST_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});
export const templateEOI = axios.create({
  baseURL: process.env.VUE_APP_INTEREST_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const companies = axios.create({
  baseURL: process.env.VUE_APP_COMPANY_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const interests = axios.create({
  baseURL: process.env.VUE_APP_INTEREST_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const stats = axios.create({
  baseURL: process.env.VUE_APP_STATS_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});

export const admin = axios.create({
  baseURL: process.env.VUE_APP_IDENTITY_API_URL,
  headers: {
    Accept: "text/json",
    "Content-Type": "application/json"
  }
});
