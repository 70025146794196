<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/sell_a_business.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">SELL A BUSINESS</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>Sell a Business</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <strong
              >The Rent Roll Broker is constantly looking for agency businesses
              and rent roll portfolios to place on the market for sale.
            </strong>
            <br /><br />
            <p>
              By electing to sell through The Rent Roll Broker, you are engaging
              the services of professional brokers who have agency practice
              experience as well as business sales experience. The Rent Roll
              Broker also have an extensive database of real estate principals
              that we can market directly to, easily and instantly eliminating
              the need for long and expensive marketing programs. <br /><br />
              The Rent Roll Broker undertake to ensure that all dealings are
              kept completely confidential while providing you with a sales
              assessment of your business for consideration.
            </p>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <div class="video-wrapper-grey">
              <div class="full-width-video-container">
                <iframe
                  src="https://www.youtube.com/embed/qnbxO1r5_JY?rel=0&amp;amp;showinfo=0"
                  frameborder="0"
                  allowfullscreen="true"
                ></iframe>
              </div>

              <p class="font-white">
                <strong>Janet Wundersitz </strong>– If you are selling your Rent
                Roll
              </p>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->

        <div class="gap-40"></div>
        <div class="container">
          <CustomerForm
            title="Register To Sell Your Rent Roll"
            interest="registered_seller"
            :values="{ registered_seller: 'Yes', from: 'SellBusiness' }"
            help-link="https://www.youtube.com/embed/SgkVKettLW4"
          />
        </div>
      </div>
      <!-- Container end -->
    </section>
  </div>
</template>

<script>
import { geoLocations } from "@/lib/axios";
import CustomerForm from "@/components/CustomerForm";

export default {
  components: {
    CustomerForm
  },
  computed: {
    getGeoLocations() {
      return geoLocations;
    }
  },
  data() {
    return {};
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style></style>
