<template>
  <div class="row">
    <div class="col-md-4 col-xs-12" v-for="blog in resources" :key="blog.id">
      <div class="latest-post">
        <div class="latest-post-media">
          <router-link
            class="latest-post-img"
            :to="{ name: 'industry_news_advice_show', params: { id: blog.id } }"
            ><img
              class="img-responsive"
              :src="blog.home_page_image_url"
              :alt="blog.title"
          /></router-link>
        </div>
        <div class="post-body">
          <h4 class="post-title">
            <router-link
              :to="{
                name: 'industry_news_advice_show',
                params: { id: blog.id }
              }"
              >{{ blog.title }}</router-link
            >
          </h4>
          <div class="latest-post-meta">
            <span class="post-item-date">
              <i class="fa fa-clock-o"></i> {{ blog.active_date }}
            </span>
          </div>
        </div>
      </div>
      <!-- Latest post end -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    resources: Array
  }
};
</script>
