<template>
  <div class="sidebar sidebar-right">
    <div class="widget recent-posts">
      <h3 class="widget-title">
        <i class="fa fa-download"></i>&nbsp;&nbsp;Free Documents (PDF)
      </h3>
      <ul class="unstyled clearfix">
        <li
          v-show="!isDocumentLoading && documents && documents.length"
          :key="document"
          v-for="document in documents"
        >
          <div class="posts-thumb pull-left">
            <a :href="document.file_url" target="_blank"
              ><img
                src="@/assets/images/rent_roll_broker_resources/resources_thumbs/pdf1.png"
                alt="Document Description"
            /></a>
          </div>
          <div class="post-info">
            <h4 class="entry-title">
              <a :href="document.file_url" target="_blank">{{
                document.description
              }}</a>
            </h4>
          </div>
          <div class="clearfix"></div>
        </li>
        <li v-if="isDocumentLoading" class="text-center">
          <div class="loading"></div>
        </li>

        <li v-if="!isDocumentLoading && !documents" class="text-center">
          <p>No Documents Found.</p>
        </li>
      </ul>
    </div>
    <!-- Document post end -->

    <div class="widget">
      <h3 class="widget-title">Archives</h3>
      <ul class="arrow nav nav-tabs nav-stacked" v-if="!isLoading">
        <li
          @click="handleYear(articlesYear)"
          :class="{ active: selected_year == articlesYear }"
          :key="articlesYear"
          v-for="articlesYear in articlesYears"
        >
          <a href="#">{{ articlesYear }}</a>
        </li>
      </ul>
      <ul class="arrow nav nav-tabs nav-stacked" v-if="isLoading">
        <li class="text-center">
          <div class="loading"></div>
        </li>
      </ul>
      <ul
        class="arrow nav nav-tabs nav-stacked"
        v-if="!isLoading && !articlesYears"
      >
        <li class="text-center">
          <a href="#">No Data Found...</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { news, documents } from "@/lib/axios";
import moment from "moment";

export default {
  props: {
    articlesYears: Object
  },
  data() {
    return {
      documents: [],
      isLoading: false,
      isDocumentLoading: false,
      selected_year: this.$route.query.year
        ? this.$route.query.year
        : moment().year()
    };
  },
  created() {
    this.loadDocuments();
  },
  methods: {
    async loadDocuments() {
      this.isDocumentLoading = true;
      try {
        const { data } = await documents.get(`/documents`, {
          params: {
            sort_column: "order_number",
            sort_dir: "asc"
          }
        });
        this.documents = data.data;
        this.isDocumentLoading = false;
      } catch (e) {
        console.log(e, "e");
        this.isDocumentLoading = false;
      }
    },
    handleYear(year) {
      this.selected_year = year;
      this.$emit("changeYear", year);
    }
  }
};
</script>
