<template>
  <div>
    <label v-if="label" :for="name">{{ label }}</label>
    <v-select
      :name="name"
      :options="items"
      :placeholder="placeholder"
      @filter="onSearch"
      :loading="loading"
      @selected="handleSelected"
      :value="valueData"
      :class-name="className"
      :default="defaultValue"
    />
    <span v-if="error" class="help-block text-danger">{{ error }}</span>
  </div>
</template>

<script>
import SearchDropdown from "../SearchDropdown";
import debounce from "lodash-es/debounce";
import filter from "lodash-es/filter";

export default {
  props: {
    label: String,
    name: String,
    url: String,
    error: String,
    required: {
      type: Boolean,
      default: false
    },
    selected: String,
    placeholder: String,
    className: String,
    axios: Function,
    value: Number,
    skip: {
      type: Boolean,
      default: false
    },
    showother: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "v-select": SearchDropdown
  },
  data() {
    return {
      items: [],
      loading: true,
      valueData: null,
      defaultValue: null
    };
  },
  methods: {
    onSearch(value) {
      this.loading = true;
      this.onFilter(value);
    },
    onFilter: debounce(function(value) {
      this.loadData(value);
    }, 500),
    async loadData(value = "", selected) {
      try {
        const { data } = await this.axios.get(
          `${this.url}?q=${value}&selected=${selected ? selected : ""}${this.skip ? "&skip=true" : ""}${this.showother ? "&showother=true" : ""}`
        );
        this.items = data.data;
        const selectedValue = filter(data.data, { id: selected })[0];
        if (selected && selectedValue) {
          this.$emit("selected", selectedValue);
          this.defaultValue = selectedValue;
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    handleSelected(selected) {
      this.$emit("selected", selected);
      this.defaultValue = null;
    }
  },
  mounted() {
    this.loadData("", this.value);
  }
};
</script>
