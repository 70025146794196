<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/rent_roll_broker/about_the_rent_roll_broker.jpg') + ')'}"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">About Us</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>About Us</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <!-- Banner area end -->

    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="about-title">Chris Goodway</h2>
            <h3 class="about-sub-title-no-bottom-margin">
              The Rent Roll Broker
            </h3>
            <h3 class="about-sub-title">CEO</h3>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <p>
              Chris Goodway commenced his real estate career in 1973, started
              his own real estate practice in 1978, and is the former
              Principal/Director of Property Rental Network (PRN) that was an
              exclusive property management company in Adelaide for 15 years.
              For the 16 years prior to this he had a prominent northern suburbs
              real estate agency which is still trading successfully today.
              <br /><br />
              In 1982 Chris was an inaugural member of the First National real
              estate group in South Australia and remained an actively involved
              member on the state committee until the sale of his business in
              1994.
              <br /><br />
              South Australia License: RLA 497 - RLA 213905
              <br />
              Victoria License : 073167L - 073169L
              <br />
              New South Wales License : 10062502 -  20161414
              <br />
              Western Australia License : C63625 - RA60387
            </p>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <div id="image1" class="">
              <div class="text-center">
                <img
                  class="img-responsive"
                  src="@/assets/images/rent_roll_broker/chris_goodway.jpg"
                  alt="Chris Goodway"
                />
              </div>
              <!-- Item 1 end -->
            </div>
            <!-- Single Image-->
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->

    <section class="content background-dark-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row text-center">
              <h3 class="section-sub-title font-white">Testimonials</h3>
            </div>

            <div
              id="testimonial-slide"
              class="owl-carousel owl-theme testimonial-slide"
            >
              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    I found Chris to be a very patient, professional broker. I
                    felt he always kept my best interests as a priority. He was
                    calm and knew his craft. His response to calls or emails was
                    quick. My mind was at peace, it was a very neat and tidy
                    sale. Thank you Chris
                    <br /><br />
                    <h3 class="quote-author font-120P">C & L D</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 1 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris did an exceptional job giving me lots of assistance in
                    helping me purchase the rent roll. He was always prompt in
                    returning calls and answering questions above and beyond the
                    rent roll purchase. I am very satisfied with Chris'
                    professional manner.
                    <br /><br />
                    <h3 class="quote-author font-120P">MP</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 2 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris certainly met all my needs and his feedback and
                    empathy was of a calming and informative consistent nature.
                    He valued my business at a higher than I thought figure
                    which resulted in a sale within 2 weeks to a very suitable
                    purchaser and has kept in touch. I would recommend any
                    client contemplating selling a rent roll to use his very
                    experienced services.
                    <br /><br />
                    <h3 class="quote-author font-120P">HJ</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 3 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Having recently completed a successful settlement, I can
                    recommend Chris to anyone contemplating the sale of a
                    similar property management business. I could not speak
                    highly enough of Chris' professional, no nonsense approach,
                    his attention to detail and most importantly, in my opinion
                    his constant and consistent feedback during the entire
                    process.
                    <br /><br />
                    <h3 class="quote-author font-120P">PC</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 4 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    From the outset Chris exceeded our expectations, was always
                    easily contactable, and was the consummate professional in
                    all aspects of the transaction. Chris assisted us in
                    realising our plans with this transaction which was quick,
                    painless, and a pleasing result.
                    <br /><br />
                    <h3 class="quote-author font-120P">P & N K</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris was professional, extremely efficient and easy to deal with.
                    He knew exactly what he was doing and took care of all the details.
                    Could not be happier.
                    <br/><br/>
                    <h3 class="quote-author font-120P">GH</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 5 end -->
            </div>
            <!--/ Testimonial carousel end-->
          </div>
          <!-- Col end -->
        </div>
        <!--/ Content row end -->
      </div>
      <!--/ Container end -->
    </section>
    <!-- Content end -->

    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p>
              During his career he has been involved in every facet of the
              industry, as well as having considerable experience in buying and
              selling rent rolls and his own agency practice. <br /><br />
              From 1999 till 2007 Chris was heavily involved in the property
              management committee through the Real Estate Institue of SA,
              including 3 years as Chairperson.
              <br /><br />
              Chris also holds a Certificate IV in workplace training and
              assessment, and has been involved in developing and presenting
              various training over the years, as well as assisting in rewriting
              the property management level 1 training course for the REISA in
              2006. He is keen to use this as well as his 35 years of
              experience, to assist in helping principals sell, purchase and
              develop their agency practice.
              <br /><br />
              Chris can be contacted on
              <a href="tel:0403090522">0403 090 522</a>
            </p>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <div class="video-wrapper-grey">
              <div class="full-width-video-container">
                <iframe
                  src="https://www.youtube.com/embed/bVV7e1IAiv0?rel=0&amp;amp;showinfo=0"
                  frameborder="0"
                  allowfullscreen="true"
                ></iframe>
              </div>

              <p class="font-white">
                <strong>Heather </strong>– Chris made the process really easy
              </p>
            </div>
            <!-- Col end -->
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
</template>

<script>
export default {
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style></style>
