<template>
  <div>
    <div id="box-slide" class="owl-carousel owl-theme page-slider">
      <div
        class="item"
        v-show="show"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/images/rent_roll_broker/buy_sell_rent_roll.jpg') +
            ')'
        }"
      >
        <Banner />
      </div>
    </div>
    <section class="call-to-action no-padding">
      <div class="container">
        <div class="action-style-box">
          <div class="row">
            <div class="col-md-10">
              <div class="call-to-action-text">
                <h3 class="action-title">THE RENT ROLL BROKER</h3>
                <p class="box-slide-description font-90P">
                  The Rent Roll Broker is a business broker specialising
                  exclusively in the sale of rent roll portfolios and real
                  estate agency practices in Australia.
                </p>
              </div>
            </div>
            <!-- Col end -->

            <div class="col-md-2">
              <div class="call-to-action-btn">
                <a
                  class="slider btn btn-primary popup"
                  href="https://www.youtube.com/embed/WC1USCBgDRE"
                >
                  <span class="post-meta-date"
                    ><i class="fa fa-play-circle"></i> Why Choose Us ?</span
                  >
                </a>
              </div>
            </div>
            <!-- col end -->
          </div>
          <!-- row end -->
        </div>
        <!-- Action style box -->
      </div>
      <!-- Container end -->
    </section>
    <section id="ts-features" class="ts-features">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="ts-service-box">
              <div class="ts-service-image-wrapper">
                <img
                  class="img-responsive"
                  src="@/assets/images/rent_roll_broker/priority_buyer.jpg"
                  alt="The Rent Roll Broker Priority Buyer"
                />
              </div>
              <div class="ts-service-box-img pull-left">
                <span class="social-icon font-300P font-orange"
                  ><i class="fa fa-dollar"></i
                ></span>
              </div>
              <div class="ts-service-info">
                <h4 class="service-box-title font-90P">
                  Register as a priority buyer
                </h4>
                <p>
                  As a priority buyer, develop your real estate portfolio
                  through the purchase of a rent roll.
                </p>
                <p>
                  <router-link
                    class="slider btn btn-primary"
                    :to="{ name: 'buy_a_business' }"
                    >Register to Buy</router-link
                  >
                </p>
              </div>
            </div>
            <!-- Service1 end -->
          </div>
          <!-- Col 1 end -->

          <div class="col-md-4">
            <div class="ts-service-box">
              <div class="ts-service-image-wrapper">
                <img
                  class="img-responsive"
                  src="@/assets/images/rent_roll_broker/sell_your_rent_roll.jpg"
                  alt="Sell Your Rent Roll"
                />
              </div>
              <div class="ts-service-box-img pull-left">
                <span class="social-icon font-300P font-orange"
                  ><i class="fa fa-building-o"></i
                ></span>
              </div>
              <div class="ts-service-info">
                <h4 class="service-box-title font-90P">
                  Register to sell your rent roll
                </h4>
                <p>
                  Sell your real rent roll portfolio by engaging with our
                  experienced professional brokers.
                </p>
                <p>
                  <router-link
                    class="slider btn btn-primary"
                    :to="{ name: 'sell_a_business' }"
                    >Register to Sell</router-link
                  >
                </p>
              </div>
            </div>
            <!-- Service2 end -->
          </div>
          <!-- Col 2 end -->

          <div class="col-md-4">
            <div class="ts-service-box">
              <div class="ts-service-image-wrapper">
                <img
                  class="img-responsive"
                  src="@/assets/images/rent_roll_broker/subscribe_rent_roll_broker.jpg"
                  alt="Subscribe"
                />
              </div>
              <div class="ts-service-box-img pull-left">
                <span class="social-icon font-300P font-orange"
                  ><i class="fa fa-envelope-o"></i
                ></span>
              </div>
              <div class="ts-service-info">
                <h4 class="service-box-title font-90P">
                  Not ready to buy or sell yet?
                </h4>
                <p>
                  Subscribe to our exclusive rent roll mail list for investment
                  tips and rent roll opportunities.
                </p>
                <p>
                  <router-link
                    class="slider btn btn-primary"
                    :to="{ name: 'contact_us' }"
                    >I'm Interested</router-link
                  >
                </p>
              </div>
            </div>
            <!-- Service3 end -->
          </div>
          <!-- Col 3 end -->
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <Fact />
    <section class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3 class="column-title">The Rent Roll Broker</h3>
            <p>
              The Rent Roll Broker is a business broker specialising exclusively
              in the sale of rent roll portfolios and real estate agency
              practices in Australia. <br /><br />
              Our consultants have extensive practical experience in the real
              estate industry and are recognised for their industry knowledge
              and expertise. We place high importance on customer service and
              are committed to providing you with solutions to your business
              needs in a timely and professional manner.
            </p>
            <p>
              <router-link
                class="slider btn btn-primary"
                :to="{ name: 'sell_a_business' }"
                >Read More</router-link
              >
            </p>
          </div>
          <div class="col-md-6">
            <div class="owl-carousel owl-theme page-slider small-bg">
              <div
                class="item"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/images/rent_roll_broker/rent_rolls_for_sale.jpg') +
                    ')'
                }"
              >
                <div class="container">
                  <div class="box-slider-content">
                    <div class="box-slider-text">
                      <h2 class="box-slide-title">Opportunities</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="owl-carousel owl-theme page-slider small-bg">
              <div
                class="item"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/images/rent_roll_broker/business_for_sale.jpg') +
                    ')'
                }"
              >
                <div class="container">
                  <div class="box-slider-content">
                    <div class="box-slider-text">
                      <h2 class="box-slide-title">Success</h2>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Item 1 end -->
            </div>
            <!-- Page slider end-->
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <h3 class="column-title">Businesses and Rent Rolls for Sale</h3>
            <p>
              Whether you are looking to sell your rent roll portfolio or agency
              practice, acquire, merge or arrange for succession planning of
              your business, or prepare it for sale, the process can be very
              anxious and time consuming. <br /><br />
              By dealing with the The Rent Roll Broker, you will receive the
              personal, expert, discreet, and confidential assistance and
              support you need.
            </p>
            <p>
              <router-link
                class="slider btn btn-primary"
                :to="{ name: 'buy_a_business' }"
                >Read More</router-link
              >
            </p>
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <section class="content background-dark-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row text-center">
              <h3 class="section-sub-title font-white">Testimonials</h3>
            </div>

            <div
              id="testimonial-slide"
              class="owl-carousel owl-theme testimonial-slide"
            >
              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    I found Chris to be a very patient, professional broker. I
                    felt he always kept my best interests as a priority. He was
                    calm and knew his craft. His response to calls or emails was
                    quick. My mind was at peace, it was a very neat and tidy
                    sale. Thank you Chris
                    <br /><br />
                    <h3 class="quote-author font-120P">C & L D</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 1 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris did an exceptional job giving me lots of assistance in
                    helping me purchase the rent roll. He was always prompt in
                    returning calls and answering questions above and beyond the
                    rent roll purchase. I am very satisfied with Chris'
                    professional manner.
                    <br /><br />
                    <h3 class="quote-author font-120P">MP</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 2 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris certainly met all my needs and his feedback and
                    empathy was of a calming and informative consistent nature.
                    He valued my business at a higher than I thought figure
                    which resulted in a sale within 2 weeks to a very suitable
                    purchaser and has kept in touch. I would recommend any
                    client contemplating selling a rent roll to use his very
                    experienced services.
                    <br /><br />
                    <h3 class="quote-author font-120P">HJ</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 3 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Having recently completed a successful settlement, I can
                    recommend Chris to anyone contemplating the sale of a
                    similar property management business. I could not speak
                    highly enough of Chris' professional, no nonsense approach,
                    his attention to detail and most importantly, in my opinion
                    his constant and consistent feedback during the entire
                    process.
                    <br /><br />
                    <h3 class="quote-author font-120P">PC</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 4 end -->

              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    From the outset Chris exceeded our expectations, was always
                    easily contactable, and was the consummate professional in
                    all aspects of the transaction. Chris assisted us in
                    realising our plans with this transaction which was quick,
                    painless, and a pleasing result.
                    <br /><br />
                    <h3 class="quote-author font-120P">P & N K</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <div class="item">
                <div class="quote-item">
                  <span class="quote-text">
                    Chris was professional, extremely efficient and easy to deal with.
                    He knew exactly what he was doing and took care of all the details.
                    Could not be happier.
                    <br/><br/>
                    <h3 class="quote-author font-120P">GH</h3>
                  </span>
                </div>
                <!-- Quote item end -->
              </div>
              <!--/ Item 5 end -->
            </div>
            <!--/ Testimonial carousel end-->
          </div>
          <!-- Col end -->
        </div>
        <!--/ Content row end -->
      </div>
      <!--/ Container end -->
    </section>
    <Resource />
  </div>
</template>

<script>
import Banner from "@/components/Dashboard/Banner";
import Fact from "@/components/Dashboard/Fact";
import Resource from "@/components/Dashboard/Resource";
export default {
  components: {
    Banner,
    Fact,
    Resource
  },
  data() {
    return {show: false};
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    this.show = true;
  }
};
</script>

<style></style>
