export default {
  methods : {
    getServerValidationErrors(serverErrors) {
      const errors = {};
      Object.keys(serverErrors).forEach((key) => {
        if (key.includes(".")) {
          let keys = key.split(".");
          errors[keys[1] + "_" + keys[0]] = serverErrors[key][0];
        } else {
          errors[key] = serverErrors[key][0];
        }
      });
      return errors;
    },
    async setErrorsToHtmlElements (errors,keys = []) {
      await this.removeErrorsFromHtmlElements(errors,keys)
      Object.keys(errors).forEach((key) => {
        let element = document.getElementById(key);
        if (element) {
          element.classList.add('has-error');
          const errorSpan = document.createElement("span");
          errorSpan.setAttribute("id", `${key}_error`);
          errorSpan.innerHTML = errors[key];
          errorSpan.className = 'help-block text-danger';
          element.parentNode.insertBefore(errorSpan, element.nextSibling);
        }
      })
    },
    async removeErrorsFromHtmlElements (errors,keys = []){
      Object.keys(errors).forEach((key) => {
        let element = document.getElementById(key);
        if (element){
          element.classList.remove('has-error');
          document.getElementById(`${key}_error`)?.remove()
        }
      })

      keys.forEach((key) => {
        let element = document.getElementById(key);
        if (element){
          element.classList.remove('has-error');
          document.getElementById(`${key}_error`)?.remove()
        }
      })
    }
  }
}