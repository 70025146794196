<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <input
      class="form-control pointer"
      :name="name"
      autocomplete="off"
      :class="className"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />
    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div
        v-show="!loading"
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in options"
        :key="index"
      >
        {{ option.name }}
      </div>

      <div class="" v-show="loading">
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  template: "Dropdown",
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name"
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
      note: "Options of dropdown. An array of options with id and name"
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown"
    },
    className: {
      type: String,
      required: false,
      default: "",
      note: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown"
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
      note: "loading the dropdown"
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: "Max items showing"
    },
    value: {
      type: Object,
      required: false,
      note: "Max items showing"
    },
    default: {
      type: Object,
      required: false,
      note: "Max items showing"
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ""
    };
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit("selected", this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit("selected", this.selected);
      this.optionsShown = false;
    },
    keyMonitor: function() {
      this.$emit("filter", this.searchFilter);
    }
  },
  watch: {
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    value: {
      immediate: true,
      handler(current) {
        if (current && current.name) {
          this.searchFilter = current.name;
        }
      }
    },
    default: {
      immediate: true,
      handler(current) {
        if (!this.value && current) {
          this.selectOption(current);
        }
      }
    }
  }
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: block;
  margin: auto;
}
.dropdown .dropdown-content {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e7ecf5;
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  z-index: 1;
  max-height: 190px;
  margin-top: 2px;
}

.dropdown-content::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.dropdown-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #8a8a8a;
}

.dropdown .dropdown-content .loading {
  padding: 10px;
  text-align: center;
}
.dropdown .dropdown-content .dropdown-item {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0 20px 5px;
  cursor: pointer;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background-color: #e7ecf5;
}
.dropdown .dropdown:hover .dropdowncontent {
  display: block;
}
</style>
