<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/buy_a_business.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">BUY A BUSINESS</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>Buy a Business</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
    </div>

    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <strong
              >One of the most effective ways of developing your real estate
              practice is through the purchase of a rent roll portfolio, or if
              you are starting out or relocating, the purchase of an agency
              business may be what you are looking for.
            </strong>
            <br /><br />
            <p>
              By electing to buy through The Rent Roll Broker, you are engaging
              the services of professional brokers who have agency practice
              experience as well as business sales experience. <br /><br />
              The Rent Roll Broker undertakes to ensure that all dealings are
              kept completely confidential while providing you with superior
              service in keeping with your needs.
            </p>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <div class="video-wrapper-grey">
              <div class="full-width-video-container">
                <iframe
                  src="https://www.youtube.com/embed/m6zsxR0LvXQ?rel=0&amp;amp;showinfo=0"
                  frameborder="0"
                  allowfullscreen="true"
                ></iframe>
              </div>

              <p class="font-white">
                <strong>John </strong>– Chris is the leader in this arena
              </p>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->

        <div class="gap-40"></div>

        <div class="container">
          <CustomerForm
            title="Register as a Priority Buyer"
            interest="registered_buyer"
            :values="{ registered_buyer: 'Yes', from: 'BuyBusiness' }"
            help-link="https://www.youtube.com/embed/Zw6ReiTRopA"
          />
        </div>
      </div>
      <!-- Container end -->
    </section>
  </div>
</template>

<script>
import CustomerForm from "@/components/CustomerForm";
export default {
  components: {
    CustomerForm
  },
  data() {
    return {};
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style></style>
