import { news } from "@/lib/axios";

export const SET_RESOURCES = "SET_RESOURCES";
const state = {
  resources: []
};

// getters
const getters = {
  resources: state => {
    return state.resources;
  }
};

// actions
const actions = {
  async getResources({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await news.get("/articles?per_page=3");
      commit(SET_RESOURCES, data.data);
    } catch (error) {
      throw error;
    }
  }
};

// mutations
const mutations = {
  [SET_RESOURCES]: (state, resources) => {
    state.resources = resources;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
