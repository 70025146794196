<template>
  <div>
    <div v-if="!showSuccess">
      <div v-if="template" v-html="template"/>
    </div>
    <div v-else>
      <section id="document-body" class="main-container m-top-40">
        <div id="main-document-container" class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="">
                <h2><i class="fa fa-check font-green"></i>&nbsp;&nbsp;Sign Request Complete</h2>
                <p><br/><strong class="font-120P font-line-height-220P">We'll take over from here!</strong><br/></p>
                <p class="font-line-height-220P">Thank you, your job is done. An email has been sent to the sign
                  requestor advising them you have signed.</p>
                <p class="font-line-height-220P font-purple"><br/><strong>The Rent Roll Broker</strong></p>
              </div>
            </div><!-- Col end -->
          </div><!-- Content row end -->
        </div><!-- Document Container end -->
      </section><!-- Document body end print -->
    </div>
  </div>


</template>

<script>
import has from 'lodash-es/has'
import {templateEOI} from '@/lib/axios'
import errorMixin from '@/Mixins/errorMixin'

export default {
  mixins: [errorMixin],
  data() {
    return {
      template: null,
      showSuccess: false,
      templateData: {
        witness_typed_full_name: '',
      },
      apiUrl: '',
    }
  },
  async mounted() {
    this.apiUrl = `/web/interests/sign-eoi/${this.$route.params.customerListingUUID}/${this.$route.params.templateUUID}`

    try {
      this.template = (await templateEOI.get(this.apiUrl)).data
    } catch (e) {
      this.showSuccess = true
    }
    document.addEventListener('keyup', this.updateValues)
    document.addEventListener('click', this.clicked)
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.updateValues, true)
    window.removeEventListener('click', this.clicked, true)
  },
  methods: {
    updateValues() {
      if (has(this.templateData, event.target.id)) {
        this.templateData[event.target.id] = event.target.value
      }
    },
    async clicked() {
      //To prevent auto form fillers
      for (let key in this.templateData) {
        const element = document.getElementById(key)
        if (element) {
          this.templateData[key] = element.value
        }
      }

      if (event.target.id === 'submit' && Object.values(this.templateData).filter(n => n).length === Object.keys(this.templateData).length) {
        event.preventDefault()
        let button = document.getElementById('submit')
        this.templateData.customer_listing_uuid = this.$route.params.customerListingUUID
        try {
          button.disabled = true
          await templateEOI.patch(this.apiUrl, this.templateData)
          this.showSuccess = true
        } catch (e) {
          button.disabled = false
          let errors = this.getServerValidationErrors(e.response.data.errors)
          this.setErrorsToHtmlElements(errors)
        }
        button.disabled = false

      }
    }
  }
}
</script>
