<template>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/affiliates.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">Agents</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>About Us</li>
                  <li>Agents</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <!-- Banner area end -->

		<section id="main-container" class="main-container">
			<div class="container">
				<div class="row">

					<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">

						<div class="sidebar sidebar-left">
							<!-- List archives from last year to 2016 -->
							<div class="widget">
								<h3 class="widget-title">Agents</h3>
								<ul class="arrow nav nav-tabs nav-stacked">
                                    <li :key="item.identifier" v-for="item in summary">
										<router-link
                                            :to="{
                                                name: 'agent_detail',
                                                params: { id: item.identifier }
                                            }">
                                            <span v-bind:class="{ 'font-orange': formData.identifier===item.identifier, '': formData.identifier!==item.identifier }">{{item.first_name}} {{item.last_name}}
												<span v-if="item.sales_region">&nbsp;&nbsp;({{item.sales_region.toUpperCase()}})</span>
											</span>
                                        </router-link>
                                    </li>
								</ul>
							</div><!-- Archives end -->

						</div><!-- Sidebar end -->
					</div><!-- Sidebar Col end -->

					<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						<div class="">
							<!-- Agent details start -->
              <div class="">
                <div class="row">
                  <div class="col-md-7">
                    <h2 class="about-title">{{ profile.first_name }} {{ profile.last_name }}</h2>
                    <h3 class="about-sub-title-no-bottom-margin">{{ profile.business_name }}</h3>
                    <h3 class="about-sub-title">{{ profile.business_role }}</h3>
                    <div v-if="profile.youtube_link!==null" class="call-to-action-btn m-top-minus-20 m-bot-20">
                      <a class="btn btn-small popup" :href="`${profile.youtube_link}`">
                        <span class="post-meta-date"><i class="fa fa-play-circle"></i> Watch My Video </span></a>
                    </div>
                    <i class="fa fa-phone-square font-purple"></i>&nbsp;&nbsp;<a :href="`tel:${profile.contact_phone}`">{{ profile.contact_phone }}</a>
                    <br/><i class="fa fa-envelope-square font-purple"></i>&nbsp;&nbsp;
                      <router-link
                          :to="{
                                  hash: '#contact',
                                  params: { id: profile.identifier }
                                }">
                        Contact {{ profile.first_name }}
                      </router-link>
                    <!--<a href="#contact">Contact {{profile.first_name}}</a>-->
                  </div><!-- Col end -->

                  <div class="gap-40 MobileView"></div>

                  <div class="col-md-5">
                    <div class="text-center">
                      <img v-if="profile.profile_image_url!==null" class="img-responsive"
                           :src="profile.profile_image_url" alt="Agent Photo">
                    </div><!-- Item 1 end -->
                  </div>
                </div>

                <div class="gap-40"></div>

                <div class="row">
                  <div class="col-md-12">
                    <p><span v-html="profile.long_note"></span></p>
                  </div><!-- Col end -->
                </div><!-- Content row end -->

                <div class="gap-40"></div>

                <div id="contact" class="row" ref="main-content">
                  <div class="col-md-12">
                    <ContactForm
                        :values="formData"
                        :errors="errors"
                        :title="`Contact ${profile.first_name}`"
                        :isSubmitting="isSubmitting"
                        :success="success"
                        @send="submitHandle"/>
                  </div>
                </div>

              </div><!-- Agent details end -->

						</div><!-- post end -->

					</div><!-- Content Col end -->

				</div><!-- Main row end -->

			</div><!-- Container end -->
		</section><!-- Main container end -->

</template>

<script>
import ContactForm from "@/components/ContactForm"
import { identities } from "@/lib/axios";
import { customers } from "@/lib/axios";
import get from "lodash-es/get";

export default {
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        country_id: 14,
        state_id: null,
        registered_buyer: "",
        registered_seller: "",
        message: "",
        identifier: this.$route.params.id
      },
      profile: {},
      summary: [],
      errors: {},
      success: false,
      isSubmitting: false
    };
  },
	components: {
		ContactForm
	},
    methods: {
      async reload() {
        this.formData.identifier = this.$route.params.id;
        this.profile = {};
        this.summary = [];
        this.errors = {};
        this.success = false;
        this.isSubmitting = false;
        await this.loadProfile();
        await this.loadProfileSummary();
      },
      async loadProfile() {
        try {
          const {data} = await identities.get(`/api/profiles/${this.$route.params.id}`);
          this.profile = data.data;
        } catch (e) {
          console.log(e, "e");
        }
      },
      async loadProfileSummary() {
        try {
          const {data} = await identities.get(`/api/profiles/summary`);
          this.summary = data.data;
        } catch (e) {
          console.log(e, "e");
        }
      },
      async submitHandle(values) {
        this.isSubmitting = true;
        this.errors = {};
        try {
          let modalData = {
            ...values
          }
          await this.$recaptchaLoaded();
          modalData.re_captcha_token = await this.$recaptcha('contactAgent');

          const data = await customers.post("/web/contact-agent", modalData);
          this.formData = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            country_id: 14,
            state_id: null,
            registered_buyer: "",
            registered_seller: "",
            message: "",
            identifier: this.$route.params.id
          };
          this.success = true;
          this.isSubmitting = false;
          this.scrollMeTo('main-content');
        } catch (e) {
          this.errors = get(e, "response.data.errors", {});
          this.isSubmitting = false;
        }
      },
      scrollMeTo(e) {
        let element = this.$refs[e];
        let top = element.offsetTop;
        window.scrollTo(0, top);
      }
    },
    async created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        await this.loadProfile();
        await this.loadProfileSummary();
    },
  watch: {
    '$route.params.id'(to, from) {
      this.reload();
    }
  }
};
</script>
