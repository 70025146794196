<template>
  <section id="news" class="news">
    <div class="container">
      <div class="row text-center">
        <h2 class="section-title">Free Industry Advice and Updates</h2>
        <h3 class="section-sub-title">Resources</h3>
      </div>
      <!--/ Title row end -->
      <ResourceList :resources="resources" />
      <!--/ Content row end -->
      <div class="gap-40"></div>
      <div class="text-center">
        <router-link
          class="btn btn-primary"
          :to="{
            name: 'industry_news_advice'
          }"
          >See All</router-link
        >
      </div>
    </div>
    <!--/ Container end -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResourceList from "@/components/Resource/List";

export default {
  components: {
    ResourceList
  },
  computed: {
    ...mapGetters("resources", ["resources"])
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("resources", ["getResources"])
  },
  mounted() {
    this.getResources();
  }
};
</script>

<style></style>
