<template>
  <!--<div>-->
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/contact_the_rent_roll_broker.jpg') +
          ')'
      }">
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">Contact</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>Contact</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p>
              <strong>Want to get in touch with me?</strong> <br /><br />
              Fill out the form below to send me a message and I will try to get
              back to you within the next 48 business hours.
              <br /><br />
              Or contact me via:
              <br /><br />
              <strong>Phone</strong>
              <br />
              <a href="tel:0403090522">0403 090 522</a>
              <br />
              <strong ref="main-content">Mail</strong>
              <br />
              PO Box 447, Stepney SA 5069, Australia
            </p>
          </div>
          <!-- Col end -->

          <div class="col-md-6">
            <div class="video-wrapper-grey">
              <div class="full-width-video-container">
                <iframe
                  src="https://www.youtube.com/embed/anBMxqNvT_Y?rel=0&amp;amp;showinfo=0"
                  frameborder="0"
                  allowfullscreen="true"
                ></iframe>
              </div>

              <p class="font-white">
                <strong>Janet Wundersitz</strong> – Concerns when selling your
                Rent Roll
              </p>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Content row end -->

        <div class="gap-40"></div>
          <ContactForm
            :values="formData"
            :errors="errors"
            title="Contact Us"
            :isSubmitting="isSubmitting"
            :success="success"
            @send="submitHandle" />
      </div>
      <!-- Container end -->
    </section>
 <!-- </div>-->
</template>

<script>
import { customers } from "@/lib/axios";
import get from "lodash-es/get";
import ContactForm from "@/components/ContactForm"

export default {
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        country_id: 14,
        state_id: null,
        registered_buyer: "",
        registered_seller: "",
        message: ""
      },
      errors: {},
      success: false,
      isSubmitting: false
    };
  },
  components: {
    ContactForm
  },
  methods: {
    async submitHandle(values) {
      this.isSubmitting = true;
      this.errors = {};
      try {
        let modalData = {
          ...values
        }

        await this.$recaptchaLoaded();
        modalData.re_captcha_token = await this.$recaptcha('contactUs');

        const data = await customers.post("/web/contact-us", modalData);
        this.formData = {};
        this.success = true;
        this.isSubmitting = false;
        this.scrollMeTo('main-content')
      } catch (e) {
        this.errors = get(e, "response.data.errors", {});
        this.isSubmitting = false;
      }
    },
    scrollMeTo(e) {
      let element = this.$refs[e];
      let top = element.offsetTop;
      window.scrollTo(0, top);
    }
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style></style>
