import { stats } from "@/lib/axios";

export const SET_STATS = "SET_STATS";
const state = {
  listings: {}
};

// getters
const getters = {
  listings: state => {
    return state.listings;
  }
};

// actions
const actions = {
  async getFacts({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await stats.get("/web/stats/listings");
      commit(SET_STATS, data.data);
    } catch (error) {
      throw error;
    }
  }
};

// mutations
const mutations = {
  [SET_STATS]: (state, listings) => {
    state.listings = listings;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
