<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/the_rent_roll_broker_resources.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">Industry News & Advice</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>Resources</li>
                  <li>Industry News & Advice</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
    </div>
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div
              class="post"
              v-show="!isLoading"
              :key="article.id"
              v-for="article in articles"
            >
              <div class="post-media post-image">
                <router-link
                  :to="{
                    name: 'industry_news_advice_show',
                    params: { id: article.id }
                  }"
                >
                  <img
                    :src="article.article_page_image_url"
                    class="img-responsive"
                    alt="TRAPS WHEN SELLING YOUR RENT ROLL"
                  />
                </router-link>
              </div>

              <div class="post-body">
                <div class="entry-header">
                  <div class="post-meta">
                    <span class="post-meta-date"
                      ><i class="fa fa-calendar"></i>
                      {{ article.active_date }}</span
                    >
                  </div>
                  <h2 class="entry-title text-uppercase">
                    <router-link
                      :to="{
                        name: 'industry_news_advice_show',
                        params: { id: article.id }
                      }"
                    >
                      {{ article.title }}
                    </router-link>
                  </h2>
                </div>
                <!-- header end -->

                <div
                  class="entry-content"
                  v-html="`${article.article_body.substring(0, 200)}...`"
                ></div>

                <div class="post-footer">
                  <router-link
                    class="btn btn-primary"
                    :to="{
                      name: 'industry_news_advice_show',
                      params: { id: article.id }
                    }"
                  >
                    Continue Reading
                  </router-link>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="isLoading">
              <div class="loading"></div>
            </div>
            <div class="paging text-center" v-if="!isLoading">
              <ul class="pagination">
                <Paginate
                  :page-count="meta.last_page ? meta.last_page : 0"
                  :click-handler="pageChange"
                  :force-page="meta.current_page ? meta.current_page : 0"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  container-class="pagination"
                />
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <Archive :articles-years="articlesYears" @changeYear="changeYear" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { news } from "@/lib/axios";
import Paginate from "@/components/Paginate";
import Archive from "@/components/Archive";
import orderBy from "lodash-es/orderBy";

export default {
  components: {
    Paginate,
    Archive
  },
  data() {
    return {
      isLoading: false,
      articles: [],
      articlesYears: [],
      meta: [],
      params: {
        page: 1,
        selected_year: this.$route.query.year ? this.$route.query.year : null,
        year_filter: true
      }
    };
  },
  async created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.isLoading = true;
    await this.loadNewsYears();
    await this.loadNews();
    this.isLoading = false;
  },
  methods: {
    async loadNewsYears() {
      try {
        const { data } = await news.get(`/articles/get-years`);
        this.articlesYears = data.data;
        const year = orderBy(data.data, "desc")[0];
        this.params.selected_year = year ? year : null;
      } catch (e) {
        console.log(e, "e");
      }
    },
    async loadNews() {
      try {
        const { data } = await news.get(`/articles`, {
          params: this.params
        });
        this.articles = data.data;
        this.meta = data.meta;
      } catch (e) {
        console.log(e, "e");
      }
    },
    pageChange(page) {
      this.params = {
        page: page
      };
      this.loadNews();
    },
    changeYear(year) {
      this.params.selected_year = year;
      this.$router.push(`?year=${year}`);
      this.loadNews();
    }
  }
};
</script>

<style></style>
