<template>
  <div class="container">
    <div class="box-slider-content">
      <div class="box-slider-text">
        <h3 class="box-slide-sub-title font-200P">
          Find yourself a rent roll to buy
        </h3>
        <form id="form_1" action="" method="post" role="form">
          <div class="col-md-5">
            <div class="call-to-action-text">
              <div class="error-container"></div>
              <div class="row">
                <div>
                  <div class="form-group">
                    <Select
                      name="state"
                      required
                      placeholder="Select a state"
                      url="/states/search"
                      :axios="getGeoLocations"
                      @selected="
                        value => handleDropdownChange(value ? value.id : null)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="call-to-action-btn">
              <button
                class="btn btn-primary"
                type="button"
                form="form_1"
                :disabled="!state"
                @click="handleSearchClick"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Form/Select";
import { geoLocations } from "@/lib/axios";
export default {
  components: {
    Select
  },
  data() {
    return {
      state: false
    };
  },
  computed: {
    getGeoLocations() {
      return geoLocations;
    }
  },
  methods: {
    handleDropdownChange(value) {
      this.state = value;
    },
    handleSearchClick() {
      this.$router.push({
        name: "rent_roll_listings",
        query: { state: this.state }
      });
    }
  }
};
</script>

<style></style>
