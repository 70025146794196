<template>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/affiliates.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">Agents</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li>About Us</li>
                  <li>Agents</li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <!-- Banner area end -->

    <section id="main-container" class="main-container">
        <div class="container">
			<div class="row">
                <AgentBlock :key="profile.identifier" v-for="profile in profiles" :agent-data="profile"></AgentBlock>
            </div>
        </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->

    
    <!-- Main container end -->
</template>

<script>
import { identities } from "@/lib/axios";
import AgentBlock from "@/components/AgentBlock";

export default {
    components: {
        AgentBlock
    },
    data() {
        return {
            profiles: []
        };
    },
    methods: {
        async loadProfiles() {
            try {
                const { data } = await identities.get(`/api/profiles`);
                this.profiles = data.data;
            } catch (e) {
                console.log(e, "e");
            }
        }
    },
    async created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        await this.loadProfiles();
    }
    
};
</script>
