<template>
  <section id="facts" class="facts-area dark-bg">
    <div class="container">
      <div class="row">
        <div class="facts-wrapper" v-if="Object.keys(listings).length">
          <div class="col-sm-3 ts-facts">
            <div class="ts-facts-img"></div>
            <div class="ts-facts-content">
              <span class="ts-facts-icon"><i class="fa fa-home"></i></span>
              <h3 class="ts-facts-title">Residential Listings</h3>
              <h2 class="ts-facts-num">
                <router-link :to="{ name: 'rent_roll_listings' }"
                  ><span class="counterUp" :data-count="listings.residential"
                    >0</span
                  ></router-link
                >
              </h2>
            </div>
          </div>
          <!-- Col end -->

          <div class="col-sm-3 ts-facts">
            <div class="ts-facts-img"></div>
            <div class="ts-facts-content">
              <span class="ts-facts-icon"><i class="fa fa-industry"></i></span>
              <h3 class="ts-facts-title">Commercial Listings</h3>
              <h2 class="ts-facts-num">
                <router-link :to="{ name: 'rent_roll_listings' }"
                  ><span class="counterUp" :data-count="listings.commercial"
                    >0</span
                  ></router-link
                >
              </h2>
            </div>
          </div>
          <!-- Col end -->

          <div class="col-sm-3 ts-facts">
            <div class="ts-facts-img"></div>
            <div class="ts-facts-content">
              <span class="ts-facts-icon"><i class="fa fa-plane"></i></span>
              <h3 class="ts-facts-title">Holiday Listings</h3>
              <h2 class="ts-facts-num">
                <router-link :to="{ name: 'rent_roll_listings' }"
                  ><span class="counterUp" :data-count="listings.holiday"
                    >0</span
                  ></router-link
                >
              </h2>
            </div>
          </div>
          <!-- Col end -->

          <div class="col-sm-3 ts-facts">
            <div class="ts-facts-img"></div>
            <div class="ts-facts-content">
              <span class="ts-facts-icon"><i class="fa fa-building"></i></span>
              <h3 class="ts-facts-title">Strata Listings</h3>
              <h2 class="ts-facts-num">
                <router-link :to="{ name: 'rent_roll_listings' }"
                  ><span class="counterUp" :data-count="listings.strata"
                    >0</span
                  ></router-link
                >
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("stats", ["listings"])
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("stats", ["getFacts"])
  },
  mounted() {
    this.getFacts();
  }
};
</script>

<style></style>
