<template>
  <div
    id="banner-area"
    class="banner-area"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/rent_roll_broker/privacy.jpg') + ')'
    }"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="banner-heading">
              <h1 class="banner-title">TERMS OF USE</h1>
              <ol class="breadcrumb">
                <li class="font-white"><a href="/">Home</a></li>
                <li>Terms of Use</li>
              </ol>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>IMPORTANT PLEASE READ CAREFULLY</h2>
          <br />
          <h3 class="column-title">Website operation</h3>
          <p>
            This website is owned and operated by J.M.L.A Holdings Pty Ltd ACN
            007 885 350 trading as The Rent Roll Broker (collectively,
            &quot;JMLA&quot;, &quot;The Rent Roll Broker&quot;, &quot;we&quot;,
            &quot;us&quot; or &quot;our&quot;) under the domain name
            www.therentrollbroker.com.au (&quot;the Website&quot;).&nbsp;
          </p>
          <br /><br /><br />
          <h3 class="column-title">No warranty</h3>
          While JMLA has made every effort to ensure that the information
          presented in the Website is free from errors or omissions and/or is
          suitable for your intended use, JMLA does not warrant the accuracy,
          adequacy or completeness of any information contained in the Website.
          To the maximum extent permissible by law, JMLA disclaims all liability
          and responsibility for any direct or indirect loss or damage suffered
          by any person or entity in reliance on the information presented in
          the Website or omitted from the Website.
          <br /><br /><br />
          <h3 class="column-title">
            Access to the Website and use of the services
          </h3>
          Your access to and use of the information, materials and services
          provided on the Website is conditional upon your acceptance and
          compliance with the terms, conditions, statements, notices, policies
          and disclaimers contained on this Website ("Terms").
          <br /><br />
          Your continued use of the Website means you accept these Terms. If you
          do not wish to be bound by these Terms you must discontinue accessing,
          browsing and/or using the Website.
          <br /><br />
          We reserve the right to amend these Terms at any time. In such an
          event, we will provide notice by publishing the varied Terms on the
          Website. You accept that by doing this, we have provided you with
          sufficient notice of the variation.
          <br /><br /><br />
          <h3 class="column-title">Intellectual property rights</h3>
          The material contained on this Website is protected by copyright.
          <br /><br />
          Except to the extent permitted by relevant copyright legislation, you
          must not use, reproduce, modify, adapt, transmit, store, print,
          publish or distribute the material on the Website, or create any other
          material using material on the Website, without obtaining our prior
          written consent.
          <br /><br />
          The trademarks appearing on the Website (whether registered or
          unregistered) are primarily our trademarks or of those of our
          affiliates or advertisers. Other trademarks which may be displayed on
          the Website are trademarks of their respective owners. Such trademarks
          must not be used or modified in any way without our express written
          consent or the relevant affiliate or third party owner.
          <br /><br />
          You agree that all the materials displayed on or available through the
          Website, including without limitation any and all names, logos, data,
          information, text, graphics, advertisements and underlying software
          displayed on or available from the Website are protected by copyright,
          trademark and other intellectual property laws and are available for
          your personal use only.
          <br /><br />
          The Website, products, technology and processes contained in this
          website may be the subject of other intellectual property rights owned
          by JMLA or by third parties. No licence is granted in respect of those
          intellectual property rights other than as set out in these Terms.
          Your use of the Website must not in any way infringe the intellectual
          property rights of any person.
          <br /><br /><br />
          <h3 class="column-title">Third party website links</h3>
          The Website may contain links and pointers to other websites operated
          by third parties. Such links and pointers are provided for your
          information and convenience only. We make no representations about the
          accuracy or suitability of the information or links which may be
          provided on the Website. JMLA does not control these third party
          websites and cannot be responsible for the content or accuracy of the
          information or other material contained on these websites.
          <br /><br />
          Unless otherwise expressly stated on the Website, the provision of a
          link to an external website does not constitute an endorsement or
          approval of that website, its operator and/or any products or services
          offered on or through that website.
          <br /><br />
          It is strongly recommended that you seek independent expert advice if
          you have any concerns regarding any services, products or solutions
          that may be suitable for you.
          <br /><br />
          Before using other websites, we strongly recommend you examine the
          terms and conditions governing the use of those websites as they may
          differ from the Website. Similarly, before disclosing your personal
          information on any other website, we advise you to examine the privacy
          policies of those websites.
          <br /><br />
          You agree that by accessing any third party linked website you do so
          at entirely at your own risk. JMLA will not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with, use or reliance on any goods or
          services available on or through any third party website.
          <br /><br />
          Hyperlinks to the Website are only permitted where prior written
          consent has been obtained.
          <br /><br /><br />
          <h3 class="column-title">Right to modification and change</h3>
          We reserve the right to amend, modify, delete and make changes and
          corrections to any part of the Website at any time without notice.
          JMLA does not guarantee continued availability of any particular
          product or service advertised or referred to on the Website.
          <br /><br /><br />
          <h3 class="column-title">
            Information does not represent professional advice
          </h3>
          The information and material provided on or through the Website is
          provided as general information only and should not be regarded as a
          substitute for professional legal or financial advice. Prior to
          entering into a transaction or taking any particular course of action
          in connection with the information you receive from the Website, you
          should make your own inquiries and seek independent advice tailored to
          your specific circumstances and objectives.
          <br /><br /><br />
          <h3 class="column-title">No guarantees as to the services</h3>
          The Website may contain downloadable materials and links to external
          websites.
          <br /><br />
          JMLA is not responsible for, and has no control over, the content of
          downloadable materials or external websites.
          <br /><br />
          You understand that we cannot and do not guarantee, warrant or
          represent that a file or software of any kind, or from any source,
          available for downloading through the Website, will be free of
          infection or viruses, worms, Trojan horses or other code or defects
          that manifest contaminating or destructive properties.
          <br /><br /><br />
          <h3 class="column-title">Disclaimer and limitation of liability</h3>
          Certain rights and remedies may be available under the Trade Practices
          Act 1974 or similar legislation of other States or Territories and may
          not be permitted to be excluded, restricted or modified. Apart from
          those that cannot be excluded, JMLA and its related entities exclude
          all conditions and warranties that may be implied by law. To the
          extent permitted by law, our liability for breach of any implied
          warranty or condition that cannot be excluded is restricted, at our
          option to: the re-supply of services or payment of the cost of
          re-supply of services; or the replacement or repair of goods or
          payment of the cost of replacement or repair.
          <br /><br /><br />
          <h3 class="column-title">Privacy</h3>
          By using the Website, you agree to our Privacy Policy.
          <br /><br /><br />
          <h3 class="column-title">Security</h3>
          When using the World Wide Web, the Internet or third party networks or
          facilities, you are using networks, facilities and services that are
          beyond our control. You assume all risk and liability of your use of
          the World Wide Web, the Internet or any such third party networks,
          including any access to the Website via such media.
          <br /><br /><br />
          <h3 class="column-title">Jurisdiction</h3>
          The use of the Website is governed by, construed and enforced in
          accordance with the laws of South Australia, Australia and you
          irrevocably submit to the non-exclusive jurisdiction of the courts of
          South Australia, Australia and its respective courts of appeal in that
          regard. The Website may be accessed throughout Australia and overseas.
          JMLA makes no representations that the content of the Website complies
          with the laws (including intellectual property laws) of any country
          outside Australia. If you access this website from outside Australia,
          you do so at your own risk and are responsible for complying with the
          laws in the place where you access the Website.
          <br /><br /><br />
          <h3 class="column-title">Severability</h3>
          If any part of these Terms is held invalid, illegal or unenforceable,
          that part shall be severed and the remainder will continue to be valid
          and enforceable.
          <br /><br /><br />
          <h3 class="column-title">Reservation of rights</h3>
          Any rights not expressly granted to you herein are reserved by JMLA.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
