import { customers, admin } from "@/lib/axios";

export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_CUSTOMER = "SET_CUSTOMER";
const state = {
  user: null,
  customer: null,
  token: null,
  otpVerified: null
};
export const SET_TOKEN = "SET_TOKEN";

// getters
const getters = {
  user: state => {
    return state.user;
  },
  customer: state => {
    return state.customer;
  },
  token: state => {
    return state.token;
  },
  otpVerified: state => {
    return state.otpVerified;
  },
  loggedIn: (state, getters) => {
    return getters.user !== null && getters.token !== null && getters.otpVerified !== null;
  },
  permissions: state => {
    return state.user?.permissions;
  },
  authorizationToken: state => {
    return getters.token ? getters.token : null;
  }
};

// actions
const actions = {
  async getCustomer({ commit, state }) {
    if (!state.token) {
      commit(SET_CUSTOMER, null);
      return null;
    }
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await customers.get("/admin/customers/get-customer", {
        params: {
          email: state.user?.email
        },
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      });
      commit(SET_CUSTOMER, data.data);
    } catch (error) {
      throw error;
    }
  },
  async setUser({ commit }, values) {
    // eslint-disable-next-line no-useless-catch
    commit(SET_USER, values);
  },
  async setAuthCustomer({ commit }, values){
    commit(SET_CUSTOMER, values);
  },
};

// mutations
const mutations = {
  [SET_CUSTOMER]: (state, customer) => {
    state.customer = customer;
  },
  [SET_USER]: (state, values) => {
    state.user = values.user;
    state.token = values.token;
    state.otpVerified = values.otpVerified;
    state.customer = values.customer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
