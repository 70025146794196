<template>
  <div id="environment-banner-bar" v-bind:class="windowTop == 0 ? 'environment-banner-bar' : 'environment-banner-bar-top'">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <p class="environment-text">{{value}}</p>
        </div>
        <!--/ Top info end -->
      </div>
      <!--/ Content row end -->
    </div>
    <!--/ Container end -->
  </div>
</template>

<script>
export default {
    data() {
        return {
            windowTop: 0
        };
    },
    props: {
        value: {
            type: String
        }
    },
    created() {
        if (!this.$isServer) {
            this._scrollListener = () => {
                this.windowTop = Math.round(window.pageYOffset);
        }
        
        this._scrollListener();
        window.addEventListener("scroll", this._scrollListener, true);
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this._scrollListener, true);
    },
};
</script>

<style scoped>
.environment-banner-bar {
    padding: 5px 0px 5px 0px;
    background: #9C1B21;
    width: 100%;
    position: relative;
   
}

.environment-banner-bar-top {
    padding: 5px 0px 5px 0px;
    background: #9C1B21;
    width: 100%;
    position: fixed; 
    top: 0;
    z-index: 999999;   
}

.environment-text {
    text-align: center;
    color: #FFF;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    margin: 0;
    line-height: normal;
    display: inline-block;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
</style>
