<template>
  <form id="contact_form" @submit.prevent="$emit('send', formData)" method="post" action="">
    <!-- @submit.prevent.stop="$emit('submit', formData)" method="post" action=""> --> <!--@submit.prevent="submit">-->
    <div class="error-container"></div>
    <input
        type="hidden"
        name="type_of_interest"
        value="contact"
        id="type_of_interest"
    />

    <div class="row">
      <h2 class="font-purple">{{ title }}</h2>

      <div
          id="alert-mail-success"
          class="alert alert-success fade in"
          v-show="success">
        <a href="#"
           class="close"
           data-dismiss="alert"
           aria-label="close"
           title="close">×
        </a>
        <span class="icon"><i class="fa fa-check"></i></span>
        <strong>Success!</strong><br/>Your enquiry has been received. Someone will be in touch within the next 48
        business hours.
      </div>
      <!-- Alert success end -->
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="first_name">First name *</label>
          <input
              class="form-control"
              id="first_name"
              :class="{
                      'has-error': errors.hasOwnProperty('first_name')
                    }"
              name="first_name"
              placeholder="Your legal first name"
              type="text"
              v-model="formData.first_name"
          />
          <span
              v-if="errors.hasOwnProperty('first_name')"
              class="help-block text-danger">{{ errors.first_name[0] }}
                    </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="surname">Surname *</label>
          <input
              class="form-control"
              id="surname"
              :class="{
                      'has-error': errors.hasOwnProperty('last_name')
                    }"
              name="surname"
              placeholder="Your legal last name / surname"
              type="text"
              v-model="formData.last_name"
          />
          <span
              v-if="errors.hasOwnProperty('last_name')"
              class="help-block text-danger">{{ errors.last_name[0] }}
                  </span
                  >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Email address *</label>
          <input
              class="form-control"
              id="email"
              :class="{
                      'has-error': errors.hasOwnProperty('email')
                    }"
              name="email"
              placeholder="Best correspondence email"
              type="email"
              v-model="formData.email"
          />
          <span
              v-if="errors.hasOwnProperty('email')"
              class="help-block text-danger">{{ errors.email[0] }}
                  </span
                  >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="phone_number">Best contact number *</label>
          <input
              class="form-control"
              id="phone_number"
              name="phone_number"
              :class="{
                      'has-error': errors.hasOwnProperty('phone_number')
                    }"
              placeholder="Best correspondence phone"
              type="text"
              v-model="formData.phone_number"
          />
          <span
              v-if="errors.hasOwnProperty('phone_number')"
              class="help-block text-danger">{{ errors.phone_number[0] }}
                  </span
                  >
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="country"
              label="Country *"
              :key="formData.country_id"
              placeholder="Select a Country"
              selected="Australia"
              :value="formData.country_id ? formData.country_id : 14"
              url="/countries/search"
              :axios="getGeoLocations"
              @selected="
                    value =>
                        handleDropdownChange(
                        value ? value.id : null,
                        'country_id'
                        )
                    "
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <Select
              name="state"
              label="State *"
              :value="formData.state_id"
              :key="formData.state_id"
              placeholder="Select a state"
              selected="Australia"
              url="/states/search"
              :axios="getGeoLocations"
              @selected="
                        value =>
                            handleDropdownChange(
                            value ? value.id : null,
                            'state_id')"
              :class="{'has-error': errors.hasOwnProperty('state_id')}"
              showother
          />
          <span
              v-if="errors.hasOwnProperty('state_id')"
              class="help-block text-danger">
                        The state field is required.
                    </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="registered_buyer">Interested in Buying? </label>
          <select class="form-control"
                  id="registered_buyer"
                  name="registered_buyer"
                  v-model="formData.registered_buyer">
            <option value="" selected disabled>Please Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <span
              v-if="errors.hasOwnProperty('registered_buyer')"
              class="help-block text-danger">
              The registered buyer field is required.
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="registered_seller">Interested in Selling? </label>
          <select class="form-control"
                  id="registered_seller"
                  name="registered_seller"
                  v-model="formData.registered_seller">
            <option value="" selected disabled>Please Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <span
              v-if="errors.hasOwnProperty('registered_seller')"
              class="help-block text-danger">
              The registered seller field is required.
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="message">Enquiry *</label>
          <textarea
              class="form-control form-control-message"
              name="message"
              id="message"
              :class="{
                      'has-error': errors.hasOwnProperty('message')
                    }"
              placeholder="Your enquiry"
              rows="10"
              v-model="formData.message">
                  </textarea>
          <span
              v-if="errors.hasOwnProperty('message')"
              class="help-block text-danger">
                    {{ errors.message[0] }}
                  </span>
        </div>
      </div>
    </div>
    <div class="gap-40"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button
              :disabled="isSubmitting"
              class="btn btn-primary"
              type="submit">
            <div class="loading-btn" :class="{'loading-btn--loading': isSubmitting}">
              {{ isSubmitting ? "Sending" : "Send" }}
              <span v-if="isSubmitting" class="loading-btn--loading">
                                    <b></b>
                                    <b></b>
                                    <b></b>
                                </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Select from "@/components/Form/Select";
import {
  geoLocations
} from "@/lib/axios";
import set from "lodash-es/set";
import get from "lodash-es/get";

export default {
     props: {
        title: {
            type: String
        },
        values: {
            type: Object
        },
        errors: {
            type: Object
        },
        isSubmitting: {
            type: Boolean
        },
        success: {
          type: Boolean
        }
    },
    data() {
        return {
          //  errors: {
          //      ...this.formErrors
          //  },
            //success: false,
            //isSubmitting: false,
            agreement: false,
            formData: {
                ...this.values,
            }
        }
    },
    components: {
        Select
    },
    computed: {
        getGeoLocations() {
            return geoLocations;
        },

    },
    methods: {
        handleDropdownChange(value, param) {
            this.formData = set(this.formData, param, value);
        }
    },
    watch: {
      success:{
        handler(newItem) {
          if (newItem === true){
            this.formData = {}
          }
        },
        immediate: true
      }
    }
}
</script>
