<template>
  <div id="top-bar" class="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
          <ul class="top-info">
            <li>
              <i class="fa fa-phone">&nbsp;</i>
              <p class="info-text"><a href="tel:0403090522">0403 090 522</a></p>
            </li>
          </ul>
        </div>
        <!--/ Top info end -->

        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 top-social text-right">
          <ul class="unstyled">
            <li>
              <a
                title="Facebook"
                href="https://www.facebook.com/The-Rent-Roll-Broker-295014647236745/?ref=tn_tnmn"
                target="_blank"
              >
                <span class="social-icon">
                  <i class="fa fa-facebook" />
                </span>
              </a>
              <a
                title="Linkdin"
                href="http://au.linkedin.com/pub/chris-goodway/1a/12a/337"
                target="_blank"
              >
                <span class="social-icon"><i class="fa fa-linkedin"></i></span>
              </a>
              <a
                title="Youtube"
                href="http://www.youtube.com/user/TheRentRollBroker1?feature=mhee"
                target="_blank"
              >
                <span class="social-icon"><i class="fa fa-youtube"/></span>
              </a>
            </li>
          </ul>
        </div>
        <!--/ Top social end -->
      </div>
      <!--/ Content row end -->
    </div>
    <!--/ Container end -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
