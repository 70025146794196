<template>
  <div>
    <div
      id="banner-area"
      class="banner-area"
      :style="{
        backgroundImage:
          'url(' +
          require('@/assets/images/rent_roll_broker/rent_roll_listings.jpg') +
          ')'
      }"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="banner-heading">
                <h1 class="banner-title">RENT ROLL LISTINGS</h1>
                <ol class="breadcrumb">
                  <li class="font-white"><a href="/">Home</a></li>
                  <li class="font-white">
                    <router-link :to="{ name: 'rent_roll_listings' }"
                      >Rent Roll Listings</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
              <router-link
                class="listing-links"
                :to="{ name: 'rent_roll_listings' }"
                ><i class="fa fa-chevron-left font-purple" />
                &nbsp;&nbsp;&nbsp;Back to Search Results</router-link
              >
            </p>
          </div>
        </div>
        <div class="gap-40"></div>
        <div class="row" v-if="!isLoading && listing">
          <div class="col-md-12 listing-container">
            <div class="row">
              <div class="col-md-12 m-top-20">
                <div class="col-md-4">
                  <p>
                    <span class="listing-price">{{
                      parseFloat(listing.price) ? `$${listing.price}` : "EOI"
                    }}</span
                    >&nbsp;
                    <span class="listing-price-currency">
                      {{ listing.currency }}</span
                    >
                  </p>
                  <p class="listing-label">
                    {{
                      parseFloat(listing.price)
                        ? "ASKING PRICE"
                        : "SEEKING EXPRESSIONS OF INTEREST"
                    }}
                  </p>
                </div>
                <div class="col-md-4 m-top-12">
                  <p
                    v-if="
                      listing.status === 'Sold' ||
                        listing.status === 'Under Contract'
                    "
                  >
                    <span class="listing-status">{{ listing.status }}</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="gap-20"></div>

            <div class="row p-15">
              <div class="col-md-4 m-top-20">
                <p>
                  <span class="listing-highlight-1">{{ listing.region }}</span>
                </p>
                <p class="listing-label">REGION</p>
              </div>
              <div class="col-md-4 m-top-20">
                <p>
                  <span class="listing-highlight-1">{{
                    listing.total_properties
                  }}</span>
                </p>
                <p class="listing-label">NUMBER OF PROPERTIES IN RENT ROLL</p>
              </div>
              <div class="col-md-4 m-top-20">
                <p>
                  <span class="listing-highlight-2">{{
                    listing.reference
                  }}</span>
                </p>
                <p class="listing-label">REFERENCE NUMBER</p>
              </div>
            </div>

            <div class="gap-20"></div>

            <div class="row">
              <div id="facts" class="facts-area dark-bg-o">
                <div class="container">
                  <div class="row">
                    <div class="facts-wrapper-sm">
                      <div class="col-sm-3 ts-facts-sm p-25">
                        <div class="ts-facts-content-sm">
                          <span class="ts-facts-icon-sm"
                            ><i class="fa fa-home"></i
                          ></span>
                          <h3 class="ts-facts-title-sm">Residential</h3>
                          <h2 class="ts-facts-num-sm">
                            <router-link
                              :to="{
                                name: 'rent_roll_listings_show',
                                params: { id: listing.id }
                              }"
                            >
                              <span
                                class="counterUp"
                                :data-count="listing.residential"
                                >0</span
                              >
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <!-- Col end -->

                      <div class="col-sm-3 ts-facts-sm p-25">
                        <div class="ts-facts-content-sm">
                          <span class="ts-facts-icon-sm"
                            ><i class="fa fa-industry"></i
                          ></span>
                          <h3 class="ts-facts-title-sm">
                            Commercial / Industrial
                          </h3>
                          <h2 class="ts-facts-num-sm">
                            <router-link
                              :to="{
                                name: 'rent_roll_listings_show',
                                params: { id: listing.id }
                              }"
                            >
                              <span
                                class="counterUp"
                                :data-count="listing.commercial"
                                >0</span
                              >
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <!-- Col end -->

                      <div class="col-sm-3 ts-facts-sm p-25">
                        <div class="ts-facts-content-sm">
                          <span class="ts-facts-icon-sm"
                            ><i class="fa fa-plane"></i
                          ></span>
                          <h3 class="ts-facts-title-sm">Holiday</h3>
                          <h2 class="ts-facts-num-sm">
                            <router-link
                              :to="{
                                name: 'rent_roll_listings_show',
                                params: { id: listing.id }
                              }"
                            >
                              <span
                                class="counterUp"
                                :data-count="listing.holiday"
                                >0</span
                              >
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <!-- Col end -->

                      <div class="col-sm-3 ts-facts-sm p-25">
                        <div class="ts-facts-content-sm">
                          <span class="ts-facts-icon-sm"
                            ><i class="fa fa-building"></i
                          ></span>
                          <h3 class="ts-facts-title-sm">Strata / Community</h3>
                          <h2 class="ts-facts-num-sm">
                            <router-link
                              :to="{
                                name: 'rent_roll_listings_show',
                                params: { id: listing.id }
                              }"
                            >
                              <span
                                class="counterUp"
                                :data-count="listing.strata"
                                >0</span
                              >
                            </router-link>
                          </h2>
                        </div>
                      </div>
                      <!-- Col end -->
                    </div>
                    <!-- Facts end -->
                  </div>
                  <!--/ Content row end -->
                </div>
                <!--/ Container end -->
              </div>
              <!-- Facts end -->
            </div>

            <div class="gap-20"></div>

            <div class="row p-15" v-if="listing.agency_details">
              <div class="col-md-12 m-top-20">
                <p class="listing-label">AGENCY DETAILS</p>
                <p v-html="listing.agency_details"></p>
              </div>
            </div>

            <div class="row p-15" v-if="listing.rent_roll_details">
              <div class="col-md-12 m-top-20">
                <p class="listing-label">RENT ROLL DETAILS</p>
                <p v-html="listing.rent_roll_details"></p>
              </div>
            </div>

            <div class="row p-15" v-if="listing.optional_details_data">
              <div class="col-md-12 m-top-20">
                <p class="listing-label">
                  {{ listing.optional_details_label }}
                </p>
                <p v-html="listing.optional_details_data"></p>
              </div>
            </div>

            <div class="row" :class="{ 'p-15': sectionOne }">
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_management_fee_residential)"
              >
                <p>
                  <span class="listing-highlight-1"
                    >{{ listing.average_management_fee_residential }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE RESIDENTIAL</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_management_fee_commercial)"
              >
                <p>
                  <span class="listing-highlight-1"
                    >{{ listing.average_management_fee_commercial }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE COMMERCIAL</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_management_fee_holiday)"
              >
                <p>
                  <span class="listing-highlight-1"
                    >{{ listing.average_management_fee_holiday }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE HOLIDAY</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_management_fee_strata)"
              >
                <p>
                  <span class="listing-highlight-1"
                    >{{ listing.average_management_fee_strata }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE STRATA</p>
              </div>
            </div>
            <div class="row" :class="{ 'p-15': sectionOne }">
              <div
                  class="col-md-3 m-top-20"
                  v-if="parseFloat(listing.average_management_fee_storage_sheds)"
              >
                <p>
                  <span class="listing-highlight-1"
                  >{{ listing.average_management_fee_storage_sheds }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE STORAGE SHEDS</p>
              </div>
              <div
                  class="col-md-3 m-top-20"
                  v-if="parseFloat(listing.average_management_fee_airbnb)"
              >
                <p>
                  <span class="listing-highlight-1"
                  >{{ listing.average_management_fee_airbnb }}%</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE AIRBNB</p>
              </div>
            </div>
            <div class="gap-20" v-if="sectionOne"></div>
            <div class="row p-15" :class="{ 'p-15': sectionTwo }">
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.management_fee_income)"
              >
                <p>
                  <span class="listing-highlight-2"
                    >${{ listing.management_fee_income }}</span
                  >
                </p>
                <p class="listing-label">ANNUAL MANAGEMENT FEE INCOME</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.gross_income)"
              >
                <p>
                  <span class="listing-highlight-2"
                    >${{ listing.gross_income }}</span
                  >
                </p>
                <p class="listing-label">GROSS INCOME FOR THE LAST 12 MONTHS</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_rent)"
              >
                <p>
                  <span class="listing-highlight-2"
                    >${{ listing.average_rent }}</span
                  >
                </p>
                <p class="listing-label">AVERAGE RENT PER WEEK</p>
              </div>
              <div
                class="col-md-3 m-top-20"
                v-if="parseFloat(listing.average_management_fee)"
              >
                <p>
                  <span class="listing-highlight-2"
                    >${{ listing.average_management_fee }}</span
                  >
                </p>
                <p class="listing-label">AVERAGE MANAGEMENT FEE PER PROPERTY</p>
              </div>
            </div>
            <div class="gap-20" v-if="sectionTwo"></div>
            <div class="row p-15" v-if="listing.status === 'For Sale'">
              <div class="col-md-6">
                <h2 class="font-purple font-200P">Register your interest</h2>
              </div>
            </div>
            <div class="row p-15" v-if="listing.status === 'For Sale'">
              <div class="col-md-12">
                <p>
                  To register your interest in this portfolio, please provide your email address below to initiate the
                  process.<br/><br>
                  You will be prompted to log in or create an account to generate a <strong>Confidentiality
                  Agreement</strong> .<br/><br/>
                  Upon the successful completion of the Confidentiality Agreement, you will gain online access to the
                  <strong>Information Memorandum</strong> associated with this listing for your review. Furthermore, a
                  representative will reach out to you within the next 48 business hours.<br/><br/>
                </p>
                <p v-if="!loggedIn && !email_verified_at">
                  <a class="btn btn-small" type="button" :href="loginUrl"><i class='fa fa-user-o'></i> Log In</a>
                  &nbsp;&nbsp;<strong class="font-orange">Log In first if you have an online account with us</strong>
                </p>
                <p v-if="!loggedIn && !email_verified_at"><br/><strong class="font-orange">If you do not have an account with us, please start
                  by entering your email address below</strong></p>
              </div>
            </div>
            <div class="row p-15" v-if="!loggedIn && !email_verified_at">
              <div class="col-md-6">
                <div class="form-group"><label for="email">Email address *</label>
                  <input class="form-control"
                         id="email" name="email"
                         placeholder="Best correspondence email"
                         type="email" v-model="email"
                         required/></div>
              </div>
              <div class="col-md-6" style="margin-top: 32px;">
                <button class="btn btn-primary" type="button" :value="emailValue" @click="checkEmail">Submit</button>
              </div>
            </div>
            <div id="alert-mail-success" class="row p-15" v-if="false">
              <div class="alert alert-success fade in">
                <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                <span class="icon"><i class="fa fa-check"></i></span><strong>Success!</strong><br/>Your registration has
                been received. Someone will be in touch within the next 48 business hours.
              </div><!-- Alert success end -->
            </div>

            <div class="enquiry-form"  v-if="(listing.status === 'For Sale' && (loggedIn || email_verified_at))">
              <div class="row p-15">
                <div :key="refreshKey" class="col-md-12">
                  <p v-show="!email_verified_at"><strong class="font-orange font-120P">Please confirm your details below</strong><br /><br /></p>
                  <CustomerForm
                    enquiry
                    interest="registered_buyer"
                    :listing="id"
                    remove-agency
                    :values="customerRegistrationForm"
                    help-link="https://www.youtube.com/embed/Zw6ReiTRopA"
                    :email_verified_at="email_verified_at"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Col end -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { geoLocations, listings, identities } from "@/lib/axios";
import CustomerForm from "@/components/CustomerForm";
import { mapGetters } from "vuex";
import get from "lodash-es/get"
import { getCookie } from '@/utils/helpers'

export default {
  props: {
    email_verified_at: [Boolean, String],
    verified_email: String,
    listing_login: [Boolean, String]
  },
  components: {
    CustomerForm
  },
  computed: {
    getGeoLocations() {
      return geoLocations;
    },
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
      user: 'user',
      customer: 'customer',
    }),
    sectionOne() {
      return (
        this.listing &&
        (parseFloat(this.listing.average_management_fee_residential) ||
          parseFloat(this.listing.average_management_fee_commercial) ||
          parseFloat(this.listing.average_management_fee_holiday) ||
          parseFloat(this.listing.average_management_fee_strata))
      );
    },
    sectionTwo() {
      return (
        this.listing &&
        (parseFloat(this.listing.management_fee_income) ||
          parseFloat(this.listing.gross_income) ||
          parseFloat(this.listing.average_rent) ||
          parseFloat(this.listing.average_management_fee))
      );
    }
  },
  data() {
    const getListingIdByRoute = this.$route.params.id;
    return {
      id: getListingIdByRoute,
      listing_id: getListingIdByRoute,
      listing: null,
      fields: {},
      errors: {},
      success: false,
      isLoading: false,
      categoryData: [],
      email: "",
      emailValue: 'email',
      isAvailable: false,
      loginUrl: `${process.env.VUE_APP_ADMIN_URL}/login?redirect=${process.env.VUE_APP_WEB_URL}/rent-roll-listings/${this.$route.params.id}`,
      customerRegistrationForm: this.getDefaultCustomerRegistrationForm(getListingIdByRoute),
      refreshKey: 0,
    };
  },
  mounted () {
    if (this.loggedIn && this.customer) {
      this.populateCustomerRegistrationForm(this.customer);
    }

    if(this.listing_login){
      setTimeout(() => {
        this.populateCustomerRegistrationForm(this.customer);
        this.refreshKey++
      },5000)
    }

    if (this.email_verified_at){
      Object.assign(this.customerRegistrationForm, {
        email: this.verified_email,
      })
    }
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.loadListingPerId();
  },
  methods: {
    async loadListingPerId() {
      this.isLoading = true;
      try {
        const { data } = await listings.get(`/listings/${this.id}`);
        this.listing = data.data;
        this.isLoading = false;
      } catch (e) {
        console.log(e, "e");
        this.isLoading = false;
      }
    },
    handleDropdownChange(value, name) {
      this.categoryData[name] = value.id;
    },
    async checkEmail() {
      try {
        const { data } = await identities.post(`api/find-email`, { "email": this.email });
        this.isAvailable = data.success;
        if (this.isAvailable) {
          window.location.href = `${this.loginUrl}&available_email=${this.email}`;
        }else {
          window.location.href = `${process.env.VUE_APP_ADMIN_URL}/email-otp-verification?non_user=${this.email}&listing_ulid=${this.$route.params.id}`
        }
      } catch (e) {
        this.errors = {
          ...this.errors,
          business: get(e, "response.data.errors"),
          ...get(e, "response.data.errors")

        };
      }
    },
    getDefaultCustomerRegistrationForm(listingId) {
      return {
        registered_buyer: 'Yes',
        registered_seller: 'No',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        business_role: '',
        listing_id: listingId,
        non_user: 'Yes',
        company_id: '',
        business: {
          trading_name: '',
          name: '',
          abn: '',
          street_address: '',
          suburb: '',
          state_id: '',
          postcode: '',
          country_id: '',
        }
      };
    },
    populateCustomerRegistrationForm(customer) {
      const customerContact = get(customer, 'contact', {});
      const customerBusiness = get(customer, 'business', {});
      const customerCompany = get(customer, 'company', {});

      Object.assign(this.customerRegistrationForm, {
        first_name: customerContact.first_name,
        last_name: customerContact.last_name,
        email: customerContact.email,
        phone_number: customerContact.phone_number,
        business_role: customerContact.business_role,
        non_user: "No",
        company_id: customerCompany.id,
        business: {
          trading_name: customerBusiness.trading_name,
          name: customerBusiness.name,
          abn: customerBusiness.abn,
          street_address: customerBusiness.street_address,
          suburb: customerBusiness.suburb,
          state_id: customerBusiness.state_id,
          postcode: customerBusiness.postcode,
          country_id: customerBusiness.country_id,
        }
      });
    }
  }
};
</script>

<style>
.newvideo {
  width: 100%;
}
</style>
