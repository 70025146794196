
export function removeProtocol(url) {
  return url.replace(/^(https?:\/\/)?/, '');
}

export function setCookie(name, value, domain, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${value || ""}${expires}; domain=${domain}; path=/`;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

export function whichBrowser() {
  if (isFirefox()) {
    return "Firefox";
  } else if (isEdge()) {
    return "Edge";
  } else if (isIE()) {
    return "Internet Explorer";
  } else if (isOpera()) {
    return "Opera";
  } else if (isChrome()) {
    return "Chrome";
  } else if (isSafari()) {
    return "Safari";
  } else {
    return "Unknown";
  }
}

function agentHas(keyword) {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

function isIE() {
  return !!document.documentMode;
}

function isSafari() {
  return (!!window.ApplePaySetupFeature || !!window.safari) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS");
}

function isChrome() {
  return agentHas("CriOS") || agentHas("Chrome") || !!window.chrome;
}

function isFirefox() {
  return agentHas("Firefox") || agentHas("FxiOS") || agentHas("Focus");
}

function isEdge() {
  return agentHas("Edg");
}

function isOpera() {
  return agentHas("OPR");
}
