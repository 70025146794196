import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//css
import "@/assets/css/animate.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/bootstrap-datepicker.css";
import "@/assets/css/Chart.min.css";
import "@/assets/css/colorbox.css";
import "@/assets/css/datatables.min.css";
import "@/assets/css/font-awesome.min.css";
//import '@/assets/css/mdb.min.css'
import "@/assets/css/owl.carousel.min.css";
import "@/assets/css/owl.theme.default.min.css";
import "@/assets/css/pure-material-switch.css";
import "@/assets/css/responsive.css";
import "@/assets/css/style.css";

//js
//import '@/assets/js/jquery.js'
import "@/assets/js/bootstrap.min.js";
import "@/assets/js/bootstrap-datepicker.js";
import "@/assets/js/Chart.bundle.min.js";
import "@/assets/js/datatables.min.js";
import "@/assets/js/gmap3.js";
import "@/assets/js/html5shiv.js";
import "@/assets/js/ini.isotope.js";
//import '@/assets/js/isotope.js'
import "@/assets/js/jquery.colorbox.js";
import "@/assets/js/owl.carousel.min.js";
import "@/assets/js/custom.js";
//import '@/assets/js/respond.min.js'
import "@/assets/js/utils.js";
import "@/assets/js/validator.js";
import "@/assets/js/wow.min.js";

import { VueReCaptcha } from 'vue-recaptcha-v3'

window.Vapor = require("laravel-vapor");
createApp(App)
    .use(router)
    .use(store)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RE_CAPTCHA_KEY })
    .mount("#app");
