import { configs } from "@/lib/axios";
import map from "lodash-es/map";

export const CONFIGS = "CONFIGS";
const state = {
  menus: [],
  footer: []
};

// getters
const getters = {
  menus: state => {
    return state.menus;
  },
  footer: state => {
    return state.footer;
  }
};

// actions
const actions = {
  async getMenus({ commit }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await configs.get("/proxy/configurations");
      commit(CONFIGS, data);
    } catch (error) {
      throw error;
    }
  }
};

// mutations
const mutations = {
  [CONFIGS]: (state, configs) => {
    let footerData = {};
    map(configs.footer, (footer, key) => {
      let footerValue = {};
      if (footer.name) {
        footerValue = {
          ...footer,
          route: `${footer.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${footer.path}`
        };
      } else {
        footerValue = map(footer, item => {
          item.route = `${item.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${item.path}`;
          return item;
        });
      }

      footerData[key] = footerValue;
    });
    state.menus = map(configs.menus, menu => {
      if (!(menu.child && menu.child.length)) {
        menu.route = `${menu.base
          .replace("[WEB]", process.env.VUE_APP_WEB_URL)
          .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${menu.path}`;
      } else {
        menu.child = menu.child.map(childMenu => {
          childMenu.route = `${childMenu.base
            .replace("[WEB]", process.env.VUE_APP_WEB_URL)
            .replace("[ADMIN]", process.env.VUE_APP_ADMIN_URL)}${
            childMenu.path
          }`;
          return childMenu;
        });
      }
      return menu;
    });
    state.footer = footerData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
