import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import RentRollListings from "../views/RentRollListings.vue";
import EmailVerification from "../components/EmailVerification";
import CAPrepare from "../components/Templates/CA/CAPrepare";
import WitnessSign from "../components/Templates/WitnessSign";
import EOIPrepare from "../components/Templates/EOI/EOIPrepare";
import BuyBusiness from "../views/BuyBusiness.vue";
import SellBusiness from "../views/SellBusiness.vue";
import AboutUs from "../views/AboutUs.vue";
import Agents from "../views/Agents.vue";
import AgentDetail from "../views/AgentDetail.vue";
import IndustryNewsAdvice from "../views/IndustryNewsAdvice.vue";
import Affiliates from "../views/Affiliates.vue";
import ContactUs from "../views/ContactUs.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import IndustryNewsAdviceShow from "../views/IndustryNewsAdviceShow.vue";
import RentRollListingsShow from "../views/RentRollListingsShow.vue";
import Wrong from "../views/Wrong.vue";

const routes = [
  {
    path: "",
    name: "home",
    component: Home,
    meta: {
      title: "Home: The Rent Roll Broker",
      active: "/"
    }
  },
  {
    path: "/rent-roll-listings",
    name: "rent_roll_listings",
    component: RentRollListings,
    meta: {
      title: "Rent Roll Listings: The Rent Roll Broker",
      active: "/rent-roll-listings"
    }
  },
  // Email confirmation
  {
    path: "/rent-roll-listings/:customerListingUUID/email-verification/:userUUID",
    name: "email-verification",
    component: EmailVerification,
    meta: {
      title: "Email Verification The Rent Roll Broker",
      active: "/email-verification/email-verification"
    }
  },
  // Confidentiality Agreement
  {
    path: "/rent-roll-listings/confidentiality-agreement/:customerListingUUID",
    name: "confidentiality-agreement-prepare",
    component: CAPrepare,
    meta: {
      title: "Confidentiality Agreement The Rent Roll Broker",
      active: "/rent-roll-listings/confidentiality-agreement"
    }
  },
  {
    path: "/rent-roll-listings/confidentiality-agreement/:customerListingUUID/:templateUUID/sign",
    name: "confidentiality-agreement-sign",
    component: WitnessSign,
    meta: {
      title: "Confidentiality Agreement The Rent Roll Broker Sign",
      active: "/rent-roll-listings/confidentiality-agreement"
    }
  },
  {
    path: "/expression/eoi-sign/:customerListingUUID/:templateUUID/sign",
    name: "eoi-sign",
    component: EOIPrepare,
    meta: {
      title: "Expression of interest The Rent Roll Broker Sign",
      active: "/eoi-sign"
    }
  },
  {
    path: "/rent-roll-listings/expression-of-interest/:customerListingUUID/:templateUUID/sign",
    name: "expression-of-interest.witness.sign",
    component: WitnessSign,
    meta: {
      title: "Expression of interest The Rent Roll Broker Sign",
      active: "/eoi-sign"
    }
  },
  {
    path: "/buy-a-business",
    name: "buy_a_business",
    component: BuyBusiness,
    meta: {
      title: "Buy a Business: The Rent Roll Broker",
      active: "/buy-a-business"
    }
  },
  {
    path: "/sell-a-business",
    name: "sell_a_business",
    component: SellBusiness,
    meta: {
      title: "Sell a Business: The Rent Roll Broker",
      active: "/sell-a-business"
    }
  },
  {
    path: "/about-us",
    name: "about_us",
    component: AboutUs,
    meta: {
      title: "About: The Rent Roll Broker",
      active: "/about-us"
    }
  },
  {
    path: "/agents",
    name: "agents",
    component: Agents,
    meta: {
      title: "About: Agents",
      active: "/agents"
    }
  },
  {
    path: "/agents/:id",
    name: "agent_detail",
    component: AgentDetail,
    meta: {
      title: "About: Agent",
      active: "/agents"
    }
  },
  {
    path: "/industry-news-advice",
    name: "industry_news_advice",
    component: IndustryNewsAdvice,
    meta: {
      title: "Industry News & Advice: The Rent Roll Broker",
      active: "/industry-news-advice"
    }
  },
  {
    path: "/affiliates",
    name: "affiliates",
    component: Affiliates,
    meta: {
      title: "Affiliates: The Rent Roll Broker",
      active: "/affiliates"
    }
  },
  {
    path: "/contact-us",
    name: "contact_us",
    component: ContactUs,
    meta: {
      title: "Contact: The Rent Roll Broker",
      active: "/contact-us"
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: {
      title: "The Rent Roll Broker - Privacy Statement",
      active: "/privacy"
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      title: "The Rent Roll Broker - Terms of Use",
      active: "/terms"
    }
  },
  {
    path: "/industry-news-advice/:id",
    name: "industry_news_advice_show",
    component: IndustryNewsAdviceShow,
    meta: {
      title: "Industry News & Advice: The Rent Roll Broker",
      active: "/industry-news-advice"
    }
  },
  {
    path: '/rent-roll-listings/:id',
    name: 'rent_roll_listings_show',
    component: RentRollListingsShow,
    meta: {
      title: 'Rent Roll Listings: The Rent Roll Broker',
      active: '/rent-roll-listings',
    },
    props: (route) => ({
      verified_email: route.query.verified_email,
      email_verified_at: route.query.email_verified_at,
      listing_login: route.query.listing_login,
    }),
  },
  {
    path: "/wrong",
    name: "wrong",
    component: Wrong,
    meta: {
      title: "404 - Privacy Statement",
      active: "/wrong"
    }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.name === 'rent_roll_listings' || to.name === 'email-verification' || to.name === 'confidentiality-agreement-prepare' || to.name === 'rent_roll_listings_show' ) {
    window.location.href = `${process.env.VUE_APP_ADMIN_URL}${to.fullPath}`;
    return;
  }
  next();
});
export default router;