<template>
    <div class="col-md-12 p-15">
		<div class="col-md-12 p-15 agents-container">
		    <div class="col-md-3 text-left p-15">
          <router-link v-if="agentData.profile_image_url!==null"
            :to="{
              name: 'agent_detail',
              params: { id: agentData.identifier }
            }">
            <img class="img-responsive" :src="agentData.profile_image_url" alt="Agent Photo">
          </router-link>
				<div class="entry-header">
					<br />
					<i class="fa fa-phone-square font-purple"></i>&nbsp;&nbsp;<a :href="`tel:${agentData.contact_phone}`">{{ agentData.contact_phone }}</a>
					<br /><i class="fa fa-envelope-square font-purple"></i>&nbsp;&nbsp;
            <router-link
              :to="{
                name: 'agent_detail',
                hash: '#contact',
                params: { id: agentData.identifier }
              }">
              Contact {{agentData.first_name}}
            </router-link>
				</div>
			</div>
			<div class="col-md-9 text-left">
				<h4>
                    <router-link
                      :to="{
                        name: 'agent_detail',
                        params: { id: agentData.identifier }
                      }">
                      {{ agentData.first_name }} {{ agentData.last_name }}
                    </router-link>
                    
                </h4>
				<p><strong class="font-90P">
                    <router-link
                      :to="{
                        name: 'agent_detail',
                        params: { id: agentData.identifier }
                      }">
                      {{agentData.business_role}}, {{agentData.business_name}}
                    </router-link>
                </strong></p>
				<p><span v-html="agentData.short_note"></span></p>
				<p><br />
                    <router-link class="btn btn-small"
                      :to="{
                        name: 'agent_detail',
                        params: { id: agentData.identifier }
                      }">
                      Continue Reading
                    </router-link>
                </p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    props: {
        agentData: {
            type: Object,
            default: () => {}
        }
    }
}

</script>
