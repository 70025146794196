<template>
  <div
    id="banner-area"
    class="banner-area"
    :style="{
      backgroundImage:
        'url(' +
        require('@/assets/images/rent_roll_broker/affiliates.jpg') +
        ')'
    }"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="banner-heading">
              <h1 class="banner-title">Affiliates</h1>
              <ol class="breadcrumb">
                <li class="font-white"><a href="/">Home</a></li>
                <li>Resources</li>
                <li>Affiliates</li>
              </ol>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->

  <section id="main-container" class="main-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="sidebar sidebar-left">
            <!-- List archives from last year to 2016 -->
            <div class="widget">
              <h3 class="widget-title">Services by State</h3>
              <ul class="arrow nav nav-tabs nav-stacked">
                <li
                  class="pointer"
                  :key="index"
                  :class="{ active: params.state_id === state.id }"
                  v-for="(state, index) in states"
                >
                  <a @click="handleStateClick(state)"
                    >{{ state.name }} (<strong>{{
                      state.count ? state.count : 0
                    }}</strong
                    >)</a
                  >
                </li>
                <li v-if="!states.length" class="">
                  <p>None listed.</p>
                </li>
              </ul>
            </div>
            <!-- Archives end -->
          </div>
          <!-- Sidebar end -->
        </div>
        <!-- Sidebar Col end -->

        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div class="post-affiliates">
            <div v-if="!isLoading" class="post-body">
              <div class="entry-header-affiliate">
                <h2 class="entry-title-affiliate font-purple">
                  {{ selectedState ? selectedState.name : null }}
                </h2>
              </div>

              <div v-for="affiliate in affiliates" :key="affiliate.id">
                <div class="entry-header">
                  <h4>{{ affiliate.industry_name }}</h4>
                </div>
                <div class="entry-content">
                  <div class="col-md-12">
                    <div class="row">
                      <p>
                        <strong>{{ affiliate.contact_person }}</strong>
                      </p>
                    </div>

                    <div class="row">
                      {{ affiliate.name }}
                    </div>
                  </div>
                  <div class="gap-20"></div>
                  <div class="col-md-12">
                    <div class="row">
                      <i class="fa fa-map-marker font-purple"></i>
                      &nbsp;&nbsp;&nbsp;{{ affiliate.street }},
                      {{ affiliate.suburb }} {{ affiliate.state?.code }},
                      {{ affiliate.postcode }}
                    </div>
                  </div>
                  <div class="col-md-6" v-if="affiliate.contact_person">
                    <div class="row">
                      <i class="fa fa-user-circle font-purple"></i
                      >&nbsp;&nbsp;{{ affiliate.contact_person }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <i class="fa fa-phone-square font-purple"></i
                      >&nbsp;&nbsp;<a
                        :href="`tel:${affiliate.contact_number}`"
                        >{{ affiliate.contact_number }}</a
                      >
                    </div>
                  </div>
                  <div class="col-md-6" v-if="affiliate.email">
                    <div class="row">
                      <i class="fa fa-envelope-square font-purple"></i
                      >&nbsp;&nbsp;<a :href="`mailto:${affiliate.email}`">
                        {{ affiliate.email }}</a>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="affiliate.website">
                    <div class="row">
                      <i class="fa fa-globe font-purple"></i>&nbsp;&nbsp;
                      <a v-if="affiliate.website"
                        :href="`http://www.${affiliate.website}`" target="_blank"
                        >http://www.{{ affiliate.website }}</a>
                    </div>
                  </div>
                </div>
                <div class="gap-40"></div>
              </div>
            </div>
            <div v-if="isLoading" class="text-center">
              <div class="loading"></div>
            </div>
            <div v-if="!isLoading && !affiliates.length" class="">
              <p>None listed.</p>
            </div>
          </div>
        </div>

        <!-- Content Col end -->
      </div>
      <!-- Main row end -->
    </div>
    <!-- Container end -->
  </section>
  <!-- Main container end -->
</template>

<script>
import { affiliates, geoLocations } from "@/lib/axios";
import { get } from "lodash-es";
export default {
  data() {
    return {
      affiliates: [],
      states: [],
      isLoading: false,
      params: {
        state_id: null,
        page: 1
      },
      selectedState: null
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.loadStates();
    this.isLoading = false;
  },
  methods: {
    handleStateClick(state) {
      this.params.state_id = state.id;
      this.selectedState = state;
      this.loadAffiliates();
    },
    async loadAffiliates() {
      this.isLoading = true;
      if (!this.params.state_id) {
        this.affiliates = [];
        this.isLoading = false;
        return;
      }
      try {
        const { data } = await affiliates.get(`/web/affiliates`, {
          params: this.params
        });
        this.affiliates = data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e, "e");
      }
    },
    async loadStates() {
      try {
        const { data } = await geoLocations.get(`/states`, {
          params: {
            with: "count"
          }
        });
        this.states = data.data;
        if (data.data && data.data.length) {
          this.params.state_id = get(data.data, "0.id");
          this.selectedState = data.data[0];
          await this.loadAffiliates();
        }
      } catch (e) {
        console.log(e, "e");
      }
    }
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style></style>
