<template>
  <div class="body-inner" v-if="!isLoading">
    <TopBar />
    <EnvirnmentBanner v-if="host == 'therentrollbroker.net.au'" value="Testing Environment"/>
    <EnvirnmentBanner v-if="host == 'localhost:8082'" value="Dev Environment"/>
    <Header />
    <Footer />
  </div>
</template>

<script>
import TopBar from "./components/Topbar.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import EnvirnmentBanner from "./components/EnvironmentBanner.vue";
import { mapGetters, mapActions } from "vuex";
import get from 'lodash-es/get'
import { getCookie, whichBrowser } from '@/utils/helpers'

export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      host: null
    };
  },
  components: {
    TopBar,
    Header,
    Footer,
    EnvirnmentBanner
  },
  computed: {
    ...mapGetters({
      menus: "app/menus",
      footer: "app/footer",
    })
  },
  methods: {
    ...mapActions({
      getMenus: "app/getMenus",
      getCustomer: "auth/getCustomer",
      setUser: "auth/setUser",
      setAuthCustomer: "auth/setAuthCustomer"
    })
  },
  async created() {
    console.log('safari test')
    const isLoggedIn = JSON.parse(getCookie('getLoggedIn'))
    if (whichBrowser() === 'Safari'){
      if (isLoggedIn){
        console.log('safari in')
        const getAuthUser = JSON.parse(getCookie('getAuthUser'))
        const getAuthCustomer = JSON.parse(getCookie('getAuthCustomer'));
        await this.setUser(getAuthUser);
        await this.setAuthCustomer(getAuthCustomer)
      }
    }
    try{
      this.host = window.location.host;
      const CrossStorageClient = require("cross-storage").CrossStorageClient;
      const storage = new CrossStorageClient(process.env.VUE_APP_ADMIN_URL);
      await storage.onConnect().then(() => {
        storage.get("vuex").then(async values => {
          let authData = JSON.parse(values).auth;
          this.setUser(authData);
        });
      }).catch((error) => {
        console.error(error)
      });
    }catch (e){
      console.error(e)
    }
    if (!Object.values(this.menus).length || !Object.keys(this.footer).length) {
      console.log('menu')
      this.isLoading = true;
      await this.getMenus();
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
